import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FormContainer } from "../Shared/FormContainer";
import * as Components from "../../../components";
import moment from "moment";
import { useDropDownSearch, useForm } from "../../../hooks";
import {
  useCreateResellerMonthlyReportMutation,
  useGetResellerMonthlyReportQuery,
  useUpdateResellerMonthlyReportMutation,
  useGetClientsQuery,
} from "../services";
import { getLocalizedString } from "../../../shared/translation";
import { reportTypes, subTypes, summaryIntervals } from "./static";
import { reportFormValidation } from "../validations";

export const ReportsForm = ({ onClose }) => {
  const [resetCount, setResetCount] = useState(0);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [selectAllClients, setSelectAllClients] = useState(false);
  const [initialValues, setInitalValues] = useState({
    name: "",
    report_type: "",
    report_sub_type: "",
    summary_interval: "",
    metadata: {
      start_month: null,
      end_month: null,
      start_year: null,
      end_year: null,
      client_ids: [],
    },
  });

  // initialize Formik
  const formik = useFormik({
    initialValues,
    validationSchema: reportFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createReport, update: updateReport } = useForm({
    createMutation: useCreateResellerMonthlyReportMutation,
    updateMutation: useUpdateResellerMonthlyReportMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  // Get the clients list using Dropdown Search

  const dropmenuQueryParams = {
    page: 0,
    per_page: 1000,
    order_by: "created_at",
    order_dir: "desc",
  };
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: clientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_cont",
    additionalParams: { ...dropmenuQueryParams },
    selectedIds: formik.values.client_ids,
  });

  const handleFormSubmit = (formdata) => {
    // Extract metadata from selectedRange
    const metadata = {
      ...formdata.metadata,
      start_month: selectedRange.startDate.month() + 1, // moment months are 0-indexed
      end_month: selectedRange.endDate.month() + 1,
      start_year: selectedRange.startDate.year(),
      end_year: selectedRange.endDate.year(),
    };

    selectAllClients && delete metadata.client_ids;

    createReport({
      formData: {
        ...formdata,
        metadata,
      },
    });
  };

  // Add useEffect to update metadata when date range changes
  useEffect(() => {
    formik.setFieldValue("metadata", {
      start_month: selectedRange.startDate.month() + 1,
      end_month: selectedRange.endDate.month() + 1,
      start_year: selectedRange.startDate.year(),
      end_year: selectedRange.endDate.year(),
      client_ids: formik.values.metadata.client_ids,
    });
  }, [selectedRange]);

  // Handle select all clients toggle
  const handleSelectAllClients = () => {
    setSelectAllClients(!selectAllClients);
    if (!selectAllClients) {
      // If turning on "Select All", clear client_ids
      formik.setFieldValue("metadata.client_ids", []);
    }
  };

  return (
    <>
      <FormContainer
        customResourceName={getLocalizedString("report", "Report")}
        handleFormSubmit={formik.handleSubmit}
        closeForm={onClose}
        //ifTrackingApp={true}
      >
        <Components.QIInput
          label={getLocalizedString("name", "Name")}
          {...formik.getFieldProps("name")}
          error={formik.touched.name && formik.errors.name}
        />

        {/* Report Type */}
        <Components.QICustomSelect
          label={getLocalizedString("type", "Type")}
          value={formik?.values?.report_type}
          onChange={(value) => formik.setFieldValue("report_type", value)}
          error={formik.touched.report_type && formik.errors.report_type}
        >
          {reportTypes?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>

        {/* Report SubType */}
        <Components.QICustomSelect
          label={getLocalizedString("subtype", "Sub-Type")}
          value={formik?.values?.report_sub_type}
          onChange={(value) => {
            formik.setFieldValue("report_sub_type", value);
          }}
          error={formik.touched.report_sub_type && formik.errors.report_sub_type}
        >
          {subTypes?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>

        {/* Report Summary Interval */}
        {formik?.values?.report_sub_type === "summary" && (
          <Components.QICustomSelect
            label={getLocalizedString("summary_interval", "Summary Interval")}
            value={formik?.values?.summary_interval}
            onChange={(value) => {
              formik.setFieldValue("summary_interval", value);
            }}
            error={formik.touched.summary_interval && formik.errors.summary_interval}
          >
            {summaryIntervals?.map((type, index) => (
              <li value={type.id} key={index}>
                {getLocalizedString(type?.localization_key) || type?.name}
              </li>
            ))}
          </Components.QICustomSelect>
        )}

        {/* Clients */}
        <Components.QIMultiSelectDropDown
          label="Clients"
          selected={formik.values.metadata.client_ids}
          data={clients?.data || []}
          onChange={(value) => {
            formik.setFieldValue("metadata.client_ids", value);
            setClientSearch("");
          }}
          onSearch={(value) => setClientSearch(value)}
          readOnly={selectAllClients}
          error={formik.touched.metadata?.client_ids && formik.errors.metadata?.client_ids}
        />

        {/* Select All Clients Toggle */}
        <div className="mb-4">
          <Components.QISwitch
            label={getLocalizedString("select_all_clients", "Select All Clients")}
            value={selectAllClients}
            onChange={() => handleSelectAllClients()}
          />
        </div>

        {/* Replace the old month/year selection with the imported component */}
        <Components.QIMonthYearRangePicker
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          formik={formik}
        />
      </FormContainer>
    </>
  );
};
