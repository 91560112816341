import React from "react";
import moment from "moment";
import { getLocalizedString } from "../../shared/translation";
import { months } from "../../shared/dropdownLists";
import { SvgIcon } from "../../containers/Shared/SvgIcon";

export const QIMonthYearRangePicker = ({ selectedRange, setSelectedRange, formik }) => {
  const years = Array.from({ length: 10 }, (_, i) => moment().year() - i);

  // Enhanced validation function with logging for debugging
  const validateDateRange = (newStartDate, newEndDate) => {
    // Ensure we have valid moment objects
    if (
      !newStartDate ||
      !newEndDate ||
      !moment.isMoment(newStartDate) ||
      !moment.isMoment(newEndDate)
    ) {
      console.error("Invalid date objects provided to validateDateRange", {
        newStartDate,
        newEndDate,
      });
      return newEndDate;
    }

    // Compare years first
    if (newEndDate.year() < newStartDate.year()) {
      return newStartDate.clone();
    }
    // If same year, check months
    else if (
      newEndDate.year() === newStartDate.year() &&
      newEndDate.month() < newStartDate.month()
    ) {
      return newStartDate.clone();
    }

    return newEndDate;
  };

  // Update handlers to ensure they're properly setting formik values too
  const handleStartMonthChange = (e) => {
    const value = parseInt(e.target.value);
    const newStartDate = selectedRange.startDate.clone().month(value - 1);

    // Validate and adjust end date if needed
    const validatedEndDate = validateDateRange(newStartDate, selectedRange.endDate);

    // Update both the selectedRange and formik values
    setSelectedRange({
      startDate: newStartDate,
      endDate: validatedEndDate,
    });

    // Update formik values if formik is available
    if (formik && formik.setFieldValue) {
      formik.setFieldValue("metadata.start_month", value);
      formik.setFieldValue("metadata.start_year", newStartDate.year());

      // Update end date formik values if they were adjusted
      if (!validatedEndDate.isSame(selectedRange.endDate)) {
        formik.setFieldValue("metadata.end_month", validatedEndDate.month() + 1);
        formik.setFieldValue("metadata.end_year", validatedEndDate.year());
      }
    }
  };

  const handleStartYearChange = (e) => {
    const value = parseInt(e.target.value);
    const newStartDate = selectedRange.startDate.clone().year(value);

    // Validate and adjust end date if needed
    const validatedEndDate = validateDateRange(newStartDate, selectedRange.endDate);

    // Update both the selectedRange and formik values
    setSelectedRange({
      startDate: newStartDate,
      endDate: validatedEndDate,
    });

    // Update formik values if formik is available
    if (formik && formik.setFieldValue) {
      formik.setFieldValue("metadata.start_year", value);

      // Update end date formik values if they were adjusted
      if (!validatedEndDate.isSame(selectedRange.endDate)) {
        formik.setFieldValue("metadata.end_month", validatedEndDate.month() + 1);
        formik.setFieldValue("metadata.end_year", validatedEndDate.year());
      }
    }
  };

  const handleEndMonthChange = (e) => {
    const value = parseInt(e.target.value);
    const newEndDate = selectedRange.endDate.clone().month(value - 1);

    // Validate the new end date against start date
    const validatedEndDate = validateDateRange(selectedRange.startDate, newEndDate);

    // Update both the selectedRange and formik values
    setSelectedRange({
      ...selectedRange,
      endDate: validatedEndDate,
    });

    // Update formik values if formik is available
    if (formik && formik.setFieldValue) {
      formik.setFieldValue("metadata.end_month", validatedEndDate.month() + 1);
    }
  };

  const handleEndYearChange = (e) => {
    const value = parseInt(e.target.value);
    const newEndDate = selectedRange.endDate.clone().year(value);

    // Validate the new end date against start date
    const validatedEndDate = validateDateRange(selectedRange.startDate, newEndDate);

    // Update both the selectedRange and formik values
    setSelectedRange({
      ...selectedRange,
      endDate: validatedEndDate,
    });

    // Update formik values if formik is available
    if (formik && formik.setFieldValue) {
      formik.setFieldValue("metadata.end_year", validatedEndDate.year());
      formik.setFieldValue("metadata.end_month", validatedEndDate.month() + 1);
    }
  };

  return (
    <div className="qi-month-year-range-picker">
      <div className="qi-month-year-range-picker__grid">
        {/* Start Date Section */}
        <div className="qi-month-year-range-picker__date-section">
          <label className="qi-month-year-range-picker__date-label">
            <SvgIcon svgClass="qi-month-year-range-picker__icon" name="date-range" />
            {getLocalizedString("start_date", "Start Date")}
          </label>
          <div className="qi-month-year-range-picker__fields">
            <div className="qi-month-year-range-picker__field">
              <label className="qi-month-year-range-picker__field-label">
                {getLocalizedString("month", "Month")}
              </label>
              <select
                className="qi-month-year-range-picker__select"
                value={formik?.values?.metadata?.start_month || ""}
                onChange={handleStartMonthChange}
              >
                <option value="">Select Month</option>
                {months?.map((month, index) => (
                  <option value={month.value} key={index}>
                    {getLocalizedString(month?.localization_key) || month?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="qi-month-year-range-picker__field">
              <label className="qi-month-year-range-picker__field-label">
                {getLocalizedString("year", "Year")}
              </label>
              <select
                className="qi-month-year-range-picker__select"
                value={formik?.values?.metadata?.start_year || ""}
                onChange={handleStartYearChange}
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* End Date Section */}
        <div className="qi-month-year-range-picker__date-section">
          <label className="qi-month-year-range-picker__date-label">
            <SvgIcon svgClass="qi-month-year-range-picker__icon" name="date-range" />
            {getLocalizedString("end_date", "End Date")}
          </label>
          <div className="qi-month-year-range-picker__fields">
            <div className="qi-month-year-range-picker__field">
              <label className="qi-month-year-range-picker__field-label">
                {getLocalizedString("month", "Month")}
              </label>
              <select
                className="qi-month-year-range-picker__select"
                value={formik?.values?.metadata?.end_month || ""}
                onChange={handleEndMonthChange}
              >
                <option value="">Select Month</option>
                {months?.map((month, index) => (
                  <option value={month.value} key={index}>
                    {getLocalizedString(month?.localization_key) || month?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="qi-month-year-range-picker__field">
              <label className="qi-month-year-range-picker__field-label">
                {getLocalizedString("year", "Year")}
              </label>
              <select
                className="qi-month-year-range-picker__select"
                value={formik?.values?.metadata?.end_year || ""}
                onChange={handleEndYearChange}
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
