import React from "react";
import {
  Bell,
  Book,
  Calendar,
  Mobile,
  User,
  Paperclip,
  Video,
  Camera,
  Flag,
  Telephone,
  Alert,
  Folder,
  File,
  Clock,
} from "react-bytesize-icons";

const iconMap = {
  mobile: <Mobile className="icon" />,
  user: <User className="icon" />,
  book: <Book className="icon" />,
  bell: <Bell className="icon" />,
  calendar: <Calendar className="icon" />,
  video: <Video className="icon" />,
  paperclip: <Paperclip className="icon" />,
  camera: <Camera className="icon" />,
  flag: <Flag className="icon" />,
  telephone: <Telephone className="icon" />,
  alert: <Alert className="icon" />,
  folder: <Folder className="icon" />,
  file: <File className="icon" />,
  clock: <Clock className="icon" />,
};

const BytesizeIcon = ({ name }) => {
  const icon = iconMap[name?.toLowerCase()] || <span>Icon not found</span>;
  return <span className="icon-wrapper  ">{icon}</span>;
};

export default BytesizeIcon;
