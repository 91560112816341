import React, { useState } from "react";
import { usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable } from "../Shared/";
import { useGetBatchCommandsQuery, useDeleteBatchCommandMutation } from "../services";
import { BatchCommandsForm } from "./Form";
import { AdminSharedDeleteModal } from "../Shared/DeleteModal";
import { useDelete } from "../../../hooks";

export const BatchCommandContainer = () => {
  const { page, perPage, pagination } = usePagination();

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetBatchCommandsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const {
    deleteItem: deleteBatchCommand,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteBatchCommandMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  /**
   * Gets the names of the item to be deleted.
   * @param {number} id - The ID of the item to be deleted.
   * @returns {string} - The names of the item to be deleted.
   */

  const getDeleteItemName = (id) => {
    // Displays the list of commands to be deleted
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      data?.data[index]?.commands?.forEach((command) => {
        name += command.command + ", ";
      });
    }
    return name.substring(0, name.length - 2);
  };

  const headers = [
    { label: "Device Manufacturer", key: "device_manufacturer_name", className: "name more" },
    { label: "Device Model", key: "device_model_name", className: "name" },
    { label: "Vehicle Manufacturer", key: "vehicle_manufacturer_name", className: "name more" },
    { label: "Vehicle Model", key: "vehicle_manufacturer_model_name", className: "name more" },
    {
      label: "Vehicle Specification",
      nestedValue: true,
      getNestedValue: ({
        engine_capacity,
        capacity,
        vehicle_manufacturer_model_variant_name,
        vehicle_model_name,
        year,
      }) =>
        `Specification: ${vehicle_model_name},
        Variant: ${vehicle_manufacturer_model_variant_name},
         Engine Capacity: ${engine_capacity},
         Capacity: ${capacity},
         Year: ${year}
        `,
      className: "phone-number",
    },
    { label: "Created on", key: "created_at" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="batch-commands"
          auditResource="BatchCommand"
          auditKey="batch_command"
        />
      </article>

      <AdminSharedDeleteModal
        error={deleteErrorMsg}
        show={showDeleteWarning}
        resourceName="command(s)"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteBatchCommand({ id: idToDelete })}
      />

      {showForm && <BatchCommandsForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
