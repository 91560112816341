import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { getTimeGap } from "../../containers/Tracking/utils";
import { smallLogo } from "../../assets";
import sprite from "../../assets/icons.svg";
import spriteNative from "../../containers/Tracking/assets/nativeIcons/icons.svg";
import { NoData } from "../../containers/Shared";
import { useDispatch } from "react-redux";
import { setGlobalDockAlign } from "../../reduxStore/features";

export const QIDragAndDropDetails = ({
  setDetails,
  setPopup,
  deviceData,
  removeLayerOnClose,
  selectedVehiclePlate,
  removeRowSelection,
  setDockAlign,
  deviceId,
  realTimedata,
  dockAlign,
  setDeviceId,
  setShowTrack,
  headerItems = ["collapse", "split-down", "split-left", "split-right", "pop-out", "cross"],
  headerName,
  headerDetails,
  listDetails,
  setSelectedRowId,
  currentTab,
  setmaximizeMinimize,
  maximizeMinimize,
  customContent = false,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dockAlign !== "split-down") {
      if (maximizeMinimize) {
        dispatch(setGlobalDockAlign("split-down"));
        setDockAlign("split-down");
      }
    }
  }, [maximizeMinimize, dockAlign, setGlobalDockAlign, dispatch, setDockAlign]);

  const closeDeviceDetails = () => {
    removeLayerOnClose && removeLayerOnClose();
    setDetails(false);
    removeRowSelection();
    setDeviceId && setDeviceId(null);
    //setShowTrack(false);
  };

  const { status, description } = getTimeGap(
    realTimedata[deviceId]?.source_time || deviceData?.source_time?.v || deviceData?.report_time
  );

  //Header Items
  const renderHeaderItems = headerItems.map((item, index) => {
    return (
      item !== dockAlign && (
        <>
          <div
            onClick={() => {
              item === "collapse" && setmaximizeMinimize((prev) => !prev);
              item === "cross" && closeDeviceDetails();
              item === "pop-out" && setPopup();
              if (item !== "collapse") {
                item !== "cross" && dispatch(setGlobalDockAlign(item));
                setDockAlign(item);
                if (item !== "split-down") {
                  setmaximizeMinimize(false);
                }
              }
            }}
            className="details-block_actions_item"
            key={index}
          >
            <svg
              className={`icon ${
                item === "collapse" && (maximizeMinimize ? "rotate-90" : "rotate-270")
              }`}
            >
              <use
                href={`${item === "cross" || item === "collapse" ? sprite : spriteNative}#${item}`}
              ></use>
            </svg>
          </div>
        </>
      )
    );
  });
  //Header Items End
  return (
    <>
      <aside className="details-block_actions">{renderHeaderItems}</aside>

      <section
        className={`${
          (currentTab === "Map" || currentTab === "LiveTrack") &&
          Object.keys(deviceData).length === 0 &&
          deviceData.constructor === Object
            ? "no-data"
            : `details-block_section ${currentTab && `details-block_section_${currentTab}`}`
        }  `}
      >
        {!customContent && (
          <>
            {Object.keys(deviceData).length !== 0 && deviceData.constructor === Object ? (
              <>
                {headerDetails && (
                  <aside className="details-block_summary">
                    <div className="details-block_summary_item-info">
                      <div className="item-photo">
                        <img src={smallLogo} alt="logo" />
                        <span className={`status ${status}`} title={description}></span>
                      </div>
                      <ul className="details-block_summary_list">{headerDetails}</ul>
                    </div>
                  </aside>
                )}
                <div className="details-block_data-container-wrapper">
                  <Scrollbars>{listDetails}</Scrollbars>
                </div>
              </>
            ) : (
              <NoData />
            )}
          </>
        )}

        {customContent && (
          <div className={`details-block_data-container-wrapper ${currentTab}`}>{children}</div>
        )}
      </section>
    </>
  );
};
