import * as Yup from "yup";
const hexadecimalRegex = "^[a-fA-F0-9]+$";

export function isValidJSON(message) {
  return this.test("is-valid-json", message, function (value) {
    const { path, createError } = this;

    try {
      JSON.parse(JSON.stringify(value));
    } catch {
      return createError({ path, message: message ?? "Not a valid JSON" });
    }
    return true;
  });
}

export function fileSizeValidation(size, message) {
  return this.test("fileSize", message, function (value) {
    const { path, createError } = this;

    if (value && value.image_data) {
      let fileSize = value.image_data.length * (3 / 4) - 1;

      if (fileSize > size) {
        return createError({
          path,
          message: message ?? `Image size should be less than ${size / 1000}kb`,
        });
      }
    } else if (value && value.size > size) {
      return createError({
        path,
        message: message ?? `Image size should be less than ${size / 1000}kb`,
      });
    }

    return true;
  });
}

//Function to check the image type supported which accepts some default parameters
export function imageTypeValidation(
  fileTypes = ["jpg", "png", "jpeg", "gif"],
  message = "Invalid image type"
) {
  return this.test("fileType", message, (value) => {
    if (
      value == undefined ||
      value.image_extension == null ||
      fileTypes.includes(value?.image_extension)
    ) {
      return true;
    } else {
      return false;
    }
  });
}

Yup.addMethod(Yup.mixed, "json", isValidJSON);
Yup.addMethod(Yup.mixed, "image", fileSizeValidation);
Yup.addMethod(Yup.mixed, "image_type", imageTypeValidation);

export const obdCodeFormValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  code: Yup.string().required("Required")?.trim().matches(hexadecimalRegex, "Must be hexadecimal"),
  data_type: Yup.string(),
  size: Yup.number()
    .typeError("Accept any positive whole number (0 or decimal not allowed)")
    .integer("Accept any positive whole number (0 or decimal not allowed)")
    .min(1, "Accept any positive whole number (0 or decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
  start_index: Yup.number()
    .typeError("Accept 0 or any positive whole number (decimal not allowed)")
    .integer("Accept 0 or any positive whole number (decimal not allowed)")
    .min(0, "Accept 0 or any positive whole number (decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
  offset: Yup.number().typeError("Must be a number").max(999999999, "Maximum number exceeded"),
  reverse_array: Yup.boolean(),
  multiplication_factor: Yup.number().typeError("Must be a number"),
  target_variable_id: Yup.number(),
  target_section_id: Yup.number(),
  use_raw_value: Yup.boolean(),
  compare_value: Yup.number().when("data_type", {
    is: (value) => value === "switch",
    then: Yup.number().required("Required"),
  }),
  boolean_conversion: Yup.string().when("data_type", {
    is: (value) => value === "switch",
    then: Yup.string().required("Required"),
  }),
  encoding: Yup.string().when("data_type", {
    is: (value) => value === "text",
    then: Yup.string().required("Required"),
  }),
});

export const targetSectionFormValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const targetVariableFormValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const geocodersFormSchema = Yup.object({
  name: Yup.string()?.trim().required("Required"),
});

export const geolocationFormSchema = Yup.object({
  name: Yup.string()?.trim().required("Required"),
  geocoder_ids: Yup.array().min(1, "At least one geocoder item is required"),
});

export const backendsFormSchema = Yup.object({
  name: Yup.string()?.trim().required("Required"),
  backend_type: Yup.string().typeError("Invalid").required("Required"),
  host: Yup.string().required("Required"),
  path: Yup.string()
    .when("protocol", {
      is: (protocol) => protocol === "HTTP" || protocol === "HTTPS" || protocol === "MQTT",
      then: Yup.string().required("Required"),
    })
    .test("params", "Only one params is allowed", (value) => {
      let count = 0;
      for (let i = 0; i < value?.length; i++) {
        if (value[i] == ":") {
          count++;
        }
        if (count > 1) {
          return false;
        }
      }
      return true;
    })
    .test(
      "device_id",
      `Only allowed params : device_id
      <br>
      Format: (Optional Prefix)/:device_id(/optional suffix)
      <br>
      Eg: /fmc130/:device_id/data `,
      (value) => {
        let str = value?.split("/") || [];

        for (let i = 0; i < str.length; i++) {
          if (str[i].includes(":")) {
            if (str[i][0] == ":") {
              let substr = str[i].substring(1);
              if (substr == "device_id") {
                return true;
              }
              return false;
            } else {
              return false;
            }
          }
        }
        return true;
      }
    ),
  timeout: Yup.number()
    .integer("Must be integer")
    .typeError("Must be integer")
    .min(1, "Must be greater than or equal to 1")
    .max(30, "Must be less than or equal to 30")
    .required("Required"),
  maximum_retry_count: Yup.number()
    .when("protocol", {
      is: (protocol) => protocol !== "UDP",
      then: Yup.number().required("Required"),
    })
    .integer("Must be integer")
    .typeError("Must be integer")
    .min(1, "Must be greater than or equal to 1")
    .max(10, "Must be less than or equal to 10"),
  enabled: Yup.bool().typeError("Either true/false"),
  ssl_certificate: Yup.mixed()
    .nullable(true)
    .test("fileFormat", "Unsupported file type(Only .crt and .pem)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["pem", "crt"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    }),
  additional_text: Yup.mixed().json("Not a valid json"),
  keep_alive_interval: Yup.number().integer().typeError("Must be integer").min(0),
  port: Yup.number("Must be a positive number")
    .when(["backend_type"], {
      is: (backend_type) => backend_type == "TrackSynq",
      then: Yup.number().oneOf([13500], "Must be 13500"),
    })

    .integer()
    .typeError("Must be a valid port number")
    .min(1, "Must be a positive number"),
  event_ids: Yup.array().when("forwarding_option", {
    is: (forwardingOption) => forwardingOption === "events",
    then: Yup.array().min(1, "Required"),
  }),
});

export const variableMappingFormSchema = Yup.object({
  device_manufacturer_id: Yup.mixed().required("Required"),
  device_model_id: Yup.mixed().required("Required"),
  name: Yup.string().required("Required"),
  mapping_details: Yup.array().min(1, "At least one mapping is required"),
});

export const displayConfigFormSchema = Yup.object({
  name: Yup.mixed().required("Required"),
  map: Yup.mixed().required("Required"),
  //map_key: Yup.string().required("Required"),
  //variable_ids: Yup.array().min(1, "At least one mapping is required"),
});

export const deviceFormSchemaAdmin = Yup.object().shape(
  {
    client_id: Yup.mixed().required("Required"),
    device_manufacturer_id: Yup.mixed().required("Required"),
    device_model_id: Yup.mixed().required("Required"),
    name: Yup.string().required("Required"),
    device_id: Yup.string()
      .required("Required")
      .min(5)
      .max(36)
      .matches(
        /^[-/a-zA-Z0-9]+$/,
        "Must be alphanumeric, Only `-` and '/' are allowed special characters,  No spaces allowed"
      ),
  },
  []
);

export const deviceFromSchema = Yup.object().shape(
  {
    client_id: Yup.mixed().required("Required"),
    device_manufacturer_id: Yup.mixed().required("Required"),
    device_model_id: Yup.mixed().required("Required"),
    name: Yup.string().required("Required"),
    device_id: Yup.string()
      .required("Required")
      .min(5)
      .max(36)
      .matches(
        /^[-/a-zA-Z0-9]+$/,
        "Must be alphanumeric, Only `-` and '/' are allowed special characters,  No spaces allowed"
      ),
    simcard_no: Yup.string()
      .min(5, "Should have minimum 5 characters")
      .max(20, "Should have maximum 20 characters")
      .matches(/^\S*$/, "Should not contain blank space")
      .matches(/^[0-9]*$/, "Should only contain numbers"),
    country_code: Yup.string().when("simcard_phone_no", {
      is: (simCardPhoneNo) => simCardPhoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    simcard_phone_no: Yup.string().when("country_code", {
      is: (countryCode) => !countryCode,
      then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
      otherwise: Yup.string()
        .required("Required")
        .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    }),
  },
  ["country_code", "simcard_phone_no"]
);

export const userFormSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    country_code: Yup.string().when("phone_no", {
      is: (simCardPhoneNo) => simCardPhoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    // phone_no: Yup.string().when("country_code", {
    //   is: (countryCode) => !countryCode,
    //   then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    //   otherwise: Yup.string()
    //     .required("Required")
    //     .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    // }),
    picture: Yup.mixed().image_type().image(40000),
    password: Yup.string()
      .required("Required")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        `Password should meet the following criteria:
      <br>
      1. Must be at least 8 characters.
      <br>
      2. Must contain at least 1 Uppercase letter.
      <br>
      3. Must contain at least 1 Lowercase letter.
      <br>
      4. Must contain at least 1 Number.
      <br>
      5. Must contain at least 1 special character (@#$%^&*)`
      ),

    superadmin: Yup.boolean().when("client_ids", {
      is: (clientIds) => !clientIds || clientIds.length == 0,
      then: Yup.boolean().required("Select at least one role or Superadmin"),
      otherwise: Yup.boolean(),
    }),
    client_ids: Yup.array().when("superadmin", {
      is: (superadmin) => !superadmin,
      then: Yup.array().min(1, "Select Clients or mark as superadmin"),
      otherwise: Yup.array(),
    }),
    roles: Yup.array().when("superadmin", {
      is: (superadmin) => !superadmin,
      then: Yup.array().min(1, "Select at least one role"),
      otherwise: Yup.array(),
    }),
  },
  ["superadmin", "client_ids", "country_code", "phone_no", "first_name", "last_name"]
);

export const userProfileSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    picture: Yup.mixed().image_type().image(40000),
  },
  ["last_name", "first_name"]
);

export const userEditFormSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    country_code: Yup.string().when("phone_no", {
      is: (simCardPhoneNo) => simCardPhoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    // phone_no: Yup.string().when("country_code", {
    //   is: (countryCode) => !countryCode,
    //   then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    //   otherwise: Yup.string()
    //     .required("Required")
    //     .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    // }),
    picture: Yup.mixed().image_type().image(40000),
    superadmin: Yup.boolean().when("client_ids", {
      is: (clientIds) => !clientIds || clientIds.length == 0,
      then: Yup.boolean().required("Select at least one role or Superadmin"),
      otherwise: Yup.boolean(),
    }),
    client_ids: Yup.array().when("superadmin", {
      is: (superadmin) => !superadmin,
      then: Yup.array().min(1, "Select Clients or mark as superadmin"),
      otherwise: Yup.array(),
    }),
    roles: Yup.array().when("superadmin", {
      is: (superadmin) => !superadmin,
      then: Yup.array().min(1, "Select at least one role"),
      otherwise: Yup.array(),
    }),
  },
  ["superadmin", "client_ids", "country_code", "phone_no", "first_name", "last_name"]
);

export const vehicleFormSchema = Yup.object({
  name: Yup.string().required("Required").min(2),
  client_id: Yup.mixed().required("Required"),
  vehicle_model_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
  plate_number: Yup.string().required("Required"),
  engine_number: Yup.string().max(64, "Maximum 64 characters are allowed"),
  chasis_number: Yup.string().max(64, "Maximum 64 characters are allowed"),
});

export const vehicleModelFormShema = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_variant_id: Yup.mixed().required("Required"),
  year: Yup.number().integer().min(1900).max(3000),
  capacity: Yup.number().integer().min(1).typeError("Must be a number"),
});

export const clientFormSchema = Yup.object({
  name: Yup.string()
    .test("len", "Cannot have more than 40 characters", (val) => {
      if (val === undefined || val.length <= 40) {
        return true;
      }
      return false;
    })
    .required("Required"),
  email: Yup.string().email("Not a valid Email").required("Required"),
  alternate_email: Yup.string().email("Not a valid Email"),
  app_ids: Yup.array().min(1, "Add atleast one Application"),
  admin_users: Yup.array().when("admin_user_ids", {
    is: (value) => {
      return !value || value.length === 0;
    },
    then: Yup.array().min(1, "Add atleast one user"),
  }),
  retention_period: Yup.number()
    .typeError("Accept 0 or any positive whole number (decimal not allowed)")
    .integer("Accept 0 or any positive whole number (decimal not allowed)")
    .min(0, "Accept 0 or any positive whole number (decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
  config_json: Yup.string().test("valid-json", "Invalid JSON", (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }),
  device_model_manufacture: Yup.string().when(["device_auto_registration", "quick_pairing"], {
    is: (deviceAutoReg, quickPairing) => deviceAutoReg || quickPairing,
    then: Yup.string().required("Device manufacturer is required"),
    otherwise: Yup.string(),
  }),
  default_device_model_id: Yup.number().when(["device_auto_registration", "quick_pairing"], {
    is: (deviceAutoReg, quickPairing) => deviceAutoReg || quickPairing,
    then: Yup.number().required("Device model is required").nullable(),
    otherwise: Yup.number().nullable(),
  }),
  vehicle_model_manufacture: Yup.string().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.string().required("Vehicle manufacturer is required"),
    otherwise: Yup.string(),
  }),
  default_vehicle_model_id: Yup.number().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.number().required("Vehicle model is required").nullable(),
    otherwise: Yup.number().nullable(),
  }),
  vehicle_specification_id: Yup.string().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.string().required("Vehicle specification is required"),
    otherwise: Yup.string(),
  }),
}).test("conditional-validation", null, function (values) {
  const errors = {};

  // No need to add additional validation here as it's handled by the when conditions above

  return errors.length ? new Yup.ValidationError(errors) : true;
});

export const editClientFormSchema = Yup.object({
  name: Yup.string()
    .test("len", "Cannot have more than 40 characters", (val) => {
      if (val === undefined || val.length <= 40) {
        return true;
      }
      return false;
    })
    .required("Required"),
  email: Yup.string().email("Not a valid Email").required("Required"),
  alternate_email: Yup.string().email("Not a valid Email"),
  app_ids: Yup.array().min(1, "Add atleast one Application"),
  retention_period: Yup.number()
    .typeError("Must be a whole number")
    .integer("Must be a whole number")
    .min(0, "Must be a positive whole number")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
  config_json: Yup.string().test("valid-json", "Invalid JSON", (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }),
  device_model_manufacture: Yup.string().when(["device_auto_registration", "quick_pairing"], {
    is: (deviceAutoReg, quickPairing) => deviceAutoReg || quickPairing,
    then: Yup.string().required("Device manufacturer is required"),
    otherwise: Yup.string(),
  }),
  default_device_model_id: Yup.number().when(["device_auto_registration", "quick_pairing"], {
    is: (deviceAutoReg, quickPairing) => deviceAutoReg || quickPairing,
    then: Yup.number().required("Device model is required").nullable(),
    otherwise: Yup.number().nullable(),
  }),
  vehicle_model_manufacture: Yup.string().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.string().required("Vehicle manufacturer is required"),
    otherwise: Yup.string(),
  }),
  default_vehicle_model_id: Yup.number().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.number().required("Vehicle model is required").nullable(),
    otherwise: Yup.number().nullable(),
  }),
  vehicle_specification_id: Yup.string().when(["vehicle_auto_registration", "quick_pairing"], {
    is: (vehicleAutoReg, quickPairing) => vehicleAutoReg || quickPairing,
    then: Yup.string().required("Vehicle specification is required"),
    otherwise: Yup.string(),
  }),
}).test("conditional-validation", null, function (values) {
  const errors = {};

  // No need to add additional validation here as it's handled by the when conditions above

  return errors.length ? new Yup.ValidationError(errors) : true;
});

export const vehicleModelVariantFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
});

export const changePasswordFormValidation = Yup.object({
  current_password: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .notOneOf([Yup.ref("current_password")], "New password can't be same as current password")
    .matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      `Password should meet the following criteria:
    <br>
    1. Must be at least 8 characters.
    <br>
    2. Must contain at least 1 Uppercase letter.
    <br>
    3. Must contain at least 1 Lowercase letter.
    <br>
    4. Must contain at least 1 Number.
    <br>
    5. Must contain at least 1 special character (@#$%^&*)`
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
});

export const variableTypeFormValidation = Yup.object({
  name: Yup.string()
    .max(128, "Maximum 128 characters allowed")
    .matches(/^[\w.]+$/, {
      message: "Field can only contain letters, numbers, underscores, and dots",
      excludeEmptyString: true,
    })
    .required("Required"),
  display_name: Yup.string().max(256, "Maximum 256 characters allowed").required("Required"),
  section_id: Yup.mixed().required("Required"),
  unit_id: Yup.mixed().required("Required"),
  variable_type: Yup.mixed().required("Required"),
  precision: Yup.number("Must be a whole number between 0 to 5")
    .typeError("Must be a whole number between 0 to 5")
    .integer("Must be a whole number between 0 to 5")
    .min(0, "Must be a whole number between 0 to 5")
    .max(5, "Must be a whole number between 0 to 5"),
  classification_type: Yup.mixed().required("Required"),
});

export const vManModelFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.string().required("Required"),
});

export const vManFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const vClassesFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const vTypesFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const clientAdminUserValidation = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const batchCommandValidation = Yup.object({
  device_model_id: Yup.string().required("Required"),
  device_manufacturer_id: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.string().required("Required"),
  vehicle_manufacturer_model_id: Yup.string().required("Required"),
  vehicle_model_id: Yup.string().required("Required"),
  commands: Yup.array().min(1, "Required"),
});

export const DModelVariableFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  section: Yup.string().required("Required"),
  variable_type_id: Yup.mixed().required("Required"),
});

export const MobileServiceProviderFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  country_id: Yup.mixed().required("Required"),
});

export const LocalizationFormSchema = Yup.object({
  locale_id: Yup.mixed().required("Required"),
  application_variant_id: Yup.mixed().required("Required"),
  key: Yup.string()
    .required("Required")
    .max(128, "Maximum length 128")
    .matches(/^[A-Za-z0-9._-]*$/, "Supported special characters are: -, . and _"),
  value: Yup.string().required("Required").max(256, "Maximum length 256"),
});

export const commandFormSchema = Yup.object({
  device_manufacturer_id: Yup.mixed().required("Required"),
  device_model_ids: Yup.array().min(1, "Required"),
  command_type: Yup.mixed().required("Required"),
  name: Yup.string().required("Required"),
  command_text: Yup.string().required("Required"),
});

export const commandSendingSchema = Yup.object({
  delivery_mode: Yup.string()
    .when("country_code", {
      is: "+65", // Check if country_code is +65 (Singapore)
      then: Yup.string().oneOf(
        ["tcp"], // delivery_mode must be "TCP" when country_code is "+65"
        "Singapore phn no does not support SMS"
      ),
    })
    .required("Required"),
  command_type: Yup.mixed().required("Required"),
  command: Yup.string().when("command_type", {
    is: (value) => value === "raw",
    then: Yup.string().required("Required"),
  }),
});

export const LocalizationUploadFormSchema = Yup.object({
  locale_id: Yup.mixed().required("Required"),
  application_variant_id: Yup.mixed().required("Required"),
  csv_file: Yup.mixed()
    .test("fileFormat", "Unsupported file type(Only .csv)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["csv"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    })
    .required("Required"),
  csv_column_mappings: Yup.object().shape({
    text_id_column: Yup.mixed().required("Required"),
    value_column: Yup.mixed().required("Required"),
  }),
});
