import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import { vehicleFormSchema } from "../validations";
import { FormContainer } from "../Shared";
import {
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useGetVMansQuery,
  useGetVManModelsQuery,
  useGetVManModelVariantsQuery,
  useGetVehicleQuery,
  useGetVModelsQuery,
  useGetDevicesQuery,
  useGetDriversQuery,
  useGetVehicleGroupsQuery,
  useGetUsersQuery,
  useGetUsersByRoleQuery,
} from "../services";
import { useForm, useDropDownSearch } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";
import { vehiclePlaceholder } from "../../../assets";
import { ConfirmationModal } from "../Shared";
import { SvgIcon } from "../../Shared";

export const VehicleForm = ({ idToEdit = null, onClose }) => {
  const dropdownParams = { per_page: 1000 };
  const [initialValues, setInitialValues] = useState({
    name: "",
    vehicle_model_id: "",
    plate_number: "",
    engine_number: "",
    chasis_number: "",
    driver_id: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
    vehicle_manufacturer_model_variant_id: "",
    device_ids: [],
    vehicle_group_ids: [],
    assigned_user_id: "",
    image: "",
  });

  const [selectedDriver, setSelectedDriver] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  // To handle confirmation message show for edit form in case of plate number change
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // To skip confirmation message for edit form in case of plate number change when user confirms the change by clicking on Yes
  const [skipConfirmationModal, setSkipConfirmationModal] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleFormSchema,
    onSubmit: (value) => handleFormSubmit(value),
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vehicleMans,
    setSearchKey: setVManSearch,
    isSuccess: isVehicleManSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
  });

  // Get Vehicle Manufacturers Model list, if manufacture is selected using Dropdown Search
  const {
    data: vehicleManModels,
    setSearchKey: setVManModelSearch,
    isSuccess: isVehicleManModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      per_page: 1000,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
    additionalParamsToHook: { skip: !formik.values.vehicle_manufacturer_id },
  });

  /*  //Get Driver List to link with vehicle using Dropdown Search
  const {
    data: drivers = { data: [] },
    setSearchKey: setDriversSearch,
    isSuccess: isDriversSuccess,
  } = useDropDownSearch({
    useData: useGetDriversQuery,
    simpleSearchKey: "filter_full_name_cont",
    selectedIds: formik.values.driver_id,
  }); */

  //Get User List to link with vehicle using Dropdown Search
  const {
    data: users = { data: [] },
    setSearchKey: setUsersSearch,
    isSuccess: isUsersSuccess,
  } = useDropDownSearch({
    useData: useGetUsersQuery,
    simpleSearchKey: "filter_full_name_or_email_cont",
    selectedIds: formik.values.assigned_user_id,
  });

  //Get Driver List to link with vehicle using Dropdown Search
  const {
    data: drivers = { data: [] },
    setSearchKey: setDriversSearch,
    isSuccess: isDriversSuccess,
  } = useDropDownSearch({
    useData: useGetUsersByRoleQuery,
    simpleSearchKey: "filter_full_name_or_email_cont",
    selectedIds: formik.values.assigned_user_id,
    additionalParams: { role: "driver" },
  });

  const { data: vehicleGroups, isSuccess: isVehicleGroupsSuccess } =
    useGetVehicleGroupsQuery(dropdownParams);

  // Get Vehicle Manufacturer Model Variant list, if manufacture model is selected
  const { data: vehicleManModVar, isSuccess: isVehicleManModVarSuccess } =
    useGetVManModelVariantsQuery(
      {
        per_page: 1000,
        vehicle_manufacturer_model_id: formik.values.vehicle_manufacturer_model_id,
        merge_model: true,
      },
      { skip: !formik.values.vehicle_manufacturer_model_id }
    );

  const deviceParams = {
    not_assigned: true,
  };
  if (idToEdit) {
    deviceParams.vehicle_id = idToEdit;
  }

  // Get the devices list using Dropdown Search
  const {
    data: devices,
    setSearchKey: setDevicesSearchKey,
    isSuccess: devicesSuccess,
  } = useDropDownSearch({
    useData: useGetDevicesQuery,
    simpleSearchKey: "name_or_device_id_or_filter_device_ph_with_country_code_cont",
    selectedIds: formik.values.device_ids,
    additionalParams: { ...deviceParams },
    additionalParamsToHook: { ...dropdownParams },
  });

  const { data = {} } = useGetVehicleQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create: createVehicle, update: updateVehicle } = useForm({
    createMutation: useCreateVehicleMutation,
    updateMutation: useUpdateVehicleMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    // For edit form if plate number is changed then show confirmation message
    if (idToEdit && formik?.values?.plate_number !== data?.plate_number && !skipConfirmationModal) {
      setShowConfirmationModal(true);
    } else {
      if (data?.driver_id && data?.driver_id !== formik?.values?.driver_id) {
        setShowForm(true);
        return;
      }
      let fObj = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "image" && formData?.image == null) {
          fObj.append("remove_image", true);
        } else if (typeof formData[key] === "object" && key !== "image") {
          fObj.append(key, JSON.stringify(formData[key]));
        } else {
          fObj.append(key, formData[key]);
        }
      });
      if (idToEdit) {
        updateVehicle({ id: idToEdit, formData: fObj });
      } else {
        createVehicle({ formData: fObj });
      }
    }
  };

  // Confirmation message for edit form in case of plate number change
  const getConfirmationMessage = () => {
    return (
      <div className="confirmation-message">
        <span>
          <SvgIcon name="alert" className="confirmation-message_icon" />
        </span>

        <p>
          <strong>Trips, events and other track points</strong> will not be available for this
          vehicle.
        </p>

        <p> Do you still want to update?</p>
      </div>
    );
  };

  return (
    <>
      <FormContainer
        resourceName={getLocalizedString("vehicle", "Vehicle")}
        closeForm={() => onClose(false)}
        handleFormSubmit={formik.handleSubmit}
        idToEdit={idToEdit}
      >
        <Components.QIPhotoUploader
          label={getLocalizedString("upload_image", "Upload Image")}
          src={formik.values.image}
          onChange={(files) => formik.setFieldValue("image", files?.length > 0 ? files[0] : null)}
          showJustImage
          base64={false}
          initalImageUrl={formik.values.image}
          placeHolderImg={vehiclePlaceholder}
          photoGuide={`
        <h4 className="font-semibold">${getLocalizedString(
          "image_upload_guide",
          "Image Upload guide"
        )} - </h4>
        <div className="ml-2">
          <p>${getLocalizedString("max_size", "Max size")} 40kb</p>
          <p>${getLocalizedString("file_format", "File format")} .jpg/ .jpeg/ .png/ .gif</p>
          <p>${getLocalizedString(
            "preferred_image_aspect_ratio",
            "Preferred image aspect ratio"
          )} 1:1</p>
        </div>
      `}
          error={formik.touched.image && formik.errors.image}
        />

        <Components.QICustomSelect
          label={getLocalizedString("manufacturer", "Manufacturer")}
          value={formik.values.vehicle_manufacturer_id}
          onChange={(value) => {
            formik.setValues({
              ...formik.values,
              vehicle_manufacturer_id: value,
              vehicle_manufacturer_model_id: "",
              vehicle_model_id: "",
            });
            setVManSearch("");
          }}
          onSearch={setVManSearch}
          error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
          labelClassName="manufacturer"
        >
          {isVehicleManSuccess &&
            vehicleMans.data.map((vMan) => (
              <li key={vMan.id} value={vMan.id}>
                {vMan.name}
              </li>
            ))}
        </Components.QICustomSelect>
        <Components.QICustomSelect
          label={getLocalizedString("model", "Model")}
          value={formik.values.vehicle_manufacturer_model_id}
          onChange={(value) => {
            formik.setValues({
              ...formik.values,
              vehicle_manufacturer_model_id: value,
              vehicle_model_id: "",
            });
            setVManModelSearch("");
          }}
          onSearch={setVManModelSearch}
          error={
            formik.touched.vehicle_manufacturer_model_id &&
            formik.errors.vehicle_manufacturer_model_id
          }
          disabled={!formik.values.vehicle_manufacturer_id}
          labelClassName="model"
        >
          {isVehicleManModelSuccess &&
            vehicleManModels.data.map((vMan) => (
              <li key={vMan.id} value={vMan.id}>
                {vMan.name}
              </li>
            ))}
        </Components.QICustomSelect>

        <Components.QICustomSelect
          label={getLocalizedString("specification", "Specification")}
          value={formik.values.vehicle_model_id}
          onChange={(value) => {
            formik.setFieldValue("vehicle_model_id", value);
          }}
          error={formik.touched.vehicle_model_id && formik.errors.vehicle_model_id}
          disabled={!formik.values.vehicle_manufacturer_model_id}
          labelClassName="specifications"
        >
          {isVehicleManModVarSuccess &&
            vehicleManModVar.data.map((vManVar) => (
              <li key={vManVar.vehicle_model_id} value={vManVar.vehicle_model_id}>
                Name: {vManVar.name}, Engine Capacity: {vManVar.engine_capacity}, Capacity:
                {vManVar.capacity}, Year: {vManVar.year}
              </li>
            ))}
        </Components.QICustomSelect>
        <Components.QIInput
          label={getLocalizedString("name", "Name")}
          {...formik.getFieldProps("name")}
          error={formik.touched.name && formik.errors.name}
        />
        <Components.QIInput
          label={getLocalizedString("plate_no", "Plate No.")}
          {...formik.getFieldProps("plate_number")}
          error={formik.touched.plate_number && formik.errors.plate_number}
        />

        {showConfirmationModal && (
          <ConfirmationModal
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
            onConfirm={() => {
              setSkipConfirmationModal(true);
              formik.handleSubmit();
            }}
            show={showConfirmationModal}
            headerText="Confirmation Alert!"
          >
            {getConfirmationMessage()}
          </ConfirmationModal>
        )}

        <Components.QIInput
          label={getLocalizedString("engine_number", "Engine Number")}
          placeholder={getLocalizedString("engine_number", "Engine Number")}
          {...formik.getFieldProps("engine_number")}
          error={formik.touched.engine_number && formik.errors.engine_number}
        />
        <Components.QIInput
          label={getLocalizedString("chasis_number", "Chassis Number")}
          placeholder={getLocalizedString("chasis_number", "Chassis Number")}
          {...formik.getFieldProps("chasis_number")}
          error={formik.touched.chasis_number && formik.errors.chasis_number}
        />
        <Components.QIMultiSelectDropDown
          label={getLocalizedString("groups", "Groups")}
          selected={formik.values.vehicle_group_ids}
          onChange={(value) => formik.setFieldValue("vehicle_group_ids", value)}
          data={vehicleGroups?.data || []}
          className="narrow-multiselect-dropdown"
        />
        <Components.QIMultiSelectDropDown
          label={getLocalizedString("devices", "Devices")}
          labelKey="device_id"
          selected={formik.values.device_ids}
          data={devices.data}
          onChange={(data) => {
            formik.setFieldValue("device_ids", data);
            setDevicesSearchKey("");
          }}
          onSearch={(value) => setDevicesSearchKey(value)}
          error={formik.touched.device_ids && formik.errors.device_ids}
        />

        <Components.QICustomSelect
          label={getLocalizedString("user", "User")}
          value={formik.values.assigned_user_id}
          onChange={(value) => {
            formik.setFieldValue("assigned_user_id", value);
            setUsersSearch("");
          }}
          onSearch={setUsersSearch}
          onSearchPlaceHolder={getLocalizedString("search_by_name_email", "Search by Name, Email")}
          error={formik.touched.assigned_user_id && formik.errors.assigned_user_id}
          labelClassName="user"
        >
          <li value="">{getLocalizedString("select", "Select")}</li>
          {users?.data.map((user) => (
            <li value={user.id} key={user.id} className="qi-custom-select_dropdown_list_item">
              {user.first_name + " " + user.last_name + " (" + user.email + ")"}
            </li>
          ))}
        </Components.QICustomSelect>
        <Components.QICustomSelect
          label={getLocalizedString("driver", "Driver")}
          value={formik.values.driver_id}
          onChange={(value) => {
            setSelectedDriver(value);
            setShowDriverModal(true);
            setDriversSearch("");
          }}
          onSearch={setDriversSearch}
          error={formik.touched.driver_id && formik.errors.driver_id}
          labelClassName="user"
          className="temp-class"
        >
          <li value="">
            {getLocalizedString("select_a_user_as_driver", "Select a user as driver")}
          </li>
          {drivers?.data.map((user) => (
            <li value={user.id} key={user.id} className="qi-custom-select_dropdown_list_item">
              {user.first_name + " " + user.last_name + " (" + user.email + ")"}
            </li>
          ))}
        </Components.QICustomSelect>
      </FormContainer>
      <Components.QIModal className="confirm" show={showForm} size="small">
        <Components.QIModalBody>
          <h2 className="font-bold mb-5">Confirmation Alert</h2>
          <p className="">Are you sure want to edit changes?</p>
        </Components.QIModalBody>
        <Components.QIModalFooter>
          <Components.QIButton
            onClick={() => setShowForm(false)}
            className="qi-btn secondary md"
            variant="secondary"
            size="sm"
          >
            No
          </Components.QIButton>
          <Components.QIButton
            onClick={() => {
              updateVehicle({ id: idToEdit, formData: formik.values });
            }}
            size="sm"
            variant="danger"
            className="qi-btn primary md"
          >
            Yes
          </Components.QIButton>
        </Components.QIModalFooter>
      </Components.QIModal>

      <Components.QIModal className="confirm" show={showDriverModal} size="small">
        <Components.QIModalBody>
          <h2 className="font-bold mb-5">Confirmation Alert</h2>
          <p className="">
            You are about to associate a driver, this might reset previous association.
          </p>
        </Components.QIModalBody>
        <Components.QIModalFooter>
          <Components.QIButton
            onClick={() => setShowDriverModal(false)}
            className="qi-btn secondary md"
            variant="secondary"
            size="sm"
          >
            No
          </Components.QIButton>
          <Components.QIButton
            onClick={() => {
              formik.setFieldValue("driver_id", selectedDriver);
              setShowDriverModal(false);
            }}
            size="sm"
            variant="danger"
            className="qi-btn primary md"
          >
            Yes
          </Components.QIButton>
        </Components.QIModalFooter>
      </Components.QIModal>
    </>
  );
};
