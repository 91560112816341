import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useForm, useDropDownSearch } from "../../../hooks";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateBatchCommandsMutation,
  useGetBatchCommandQuery,
  useUpdateBatchCommandsMutation,
  useGetDMansQuery,
  useGetDModelsQuery,
  useGetVMansQuery,
  useGetVManModelsQuery,
  useGetVModelsQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { batchCommandValidation } from "../validations";

export const BatchCommandsForm = ({ idToEdit = null, closeForm }) => {
  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };
  const { data } = useGetBatchCommandQuery({ id: idToEdit }, { skip: !idToEdit });

  const [initialValues, setInitalValues] = useState({
    device_manufacturer_id: "",
    device_model_id: "",
    vehicle_model_id: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
    commands: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: batchCommandValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createBatchCommands, update: updateBatchCommands } = useForm({
    createMutation: useCreateBatchCommandsMutation,
    updateMutation: useUpdateBatchCommandsMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Get the device manufacturers list using Dropdown Search
  const {
    data: deviceMans,
    setSearchKey: setDManSearch,
    isSuccess: deviceMansSuccess,
  } = useDropDownSearch({
    useData: useGetDMansQuery,
    simpleSearchKey: "name_or_clients_name_cont",
    selectedIds: formik.values.device_manufacturer_id,
    additionalParams: { ...dropmenuQueryParams },
  });

  // Get the device models list using Dropdown Search, against selected devices manufacturers
  const {
    data: deviceModels,
    setSearchKey: setDeviceModelSearch,
    isSuccess: deviceModelsSuccess,
  } = useDropDownSearch({
    useData: useGetDModelsQuery,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
    additionalParams: { device_manufacturer_id: formik.values.device_manufacturer_id },
    selectedIds: formik.values.device_model_id,
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vMans = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isVmansSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
  });

  // Get Vehicle Models Model list, if manufacture is selected using Dropdown Search
  const {
    data: vehicleManModels,
    setSearchKey: setVManModelSearch,
    isSuccess: isVehicleManModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      ...dropmenuQueryParams,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
  });

  const { data: vehicleSpecifications, isSuccess: isVehicleSpecificationSuccess } =
    useGetVModelsQuery({
      per_page: 1000,
      order_by: "name",
      vehicle_manufacturer_model_id: formik.values.vehicle_manufacturer_model_id,
    });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      // Changing initial values will reset the formik
      setInitalValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    const finalFormData = {
      ...formData,
      multiplication_factor:
        formData.multiplication_factor === "" ? 1 : formData.multiplication_factor, // By default = 1
    };

    // If idToEdit then it's a edit form
    if (idToEdit) {
      updateBatchCommands({ formData: finalFormData, id: idToEdit });
    } else {
      createBatchCommands({ formData: finalFormData });
    }
  };

  const commandsHeader = [
    {
      label: "Delivery Mode",
      uiType: "select",
      key: "type",
      options: [
        {
          label: "TCP",
          value: "TCP",
        },
        {
          label: "SMS",
          value: "SMS",
        },
      ],
    },
    {
      label: "Command",
      key: "command",
    },
  ];

  const batchCommandValidator = (batchCommand) => {
    const errors = {};

    if (!batchCommand.type) {
      errors.type = "Required";
    }
    if (!batchCommand.command) {
      errors.command = "Required";
    }
    return errors;
  };

  return (
    <FormContainer
      resourceName="Batch Commands"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Device Manufacturer"
        {...formik.getFieldProps("device_manufacturer_id")}
        error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
        onChange={(value) => {
          formik.setFieldValue("device_manufacturer_id", value);
          formik.setFieldValue("device_model_id", "");
          setDManSearch("");
        }}
        onSearch={setDManSearch}
        labelClassName="device-manufacturer"
      >
        {deviceMansSuccess &&
          deviceMans.data.map((deviceModel) => (
            <li key={deviceModel.id} value={deviceModel.id}>
              {deviceModel.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Device Model"
        {...formik.getFieldProps("device_model_id")}
        error={formik.touched.device_model_id && formik.errors.device_model_id}
        onChange={(value) => {
          formik.setFieldValue("device_model_id", value);
          setDeviceModelSearch("");
        }}
        onSearch={setDeviceModelSearch}
        labelClassName="device-model"
      >
        {deviceModelsSuccess &&
          deviceModels.data.map((deviceModel) => (
            <li key={deviceModel.id} value={deviceModel.id}>
              {deviceModel.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Vehicle Manufacturer"
        {...formik.getFieldProps("vehicle_manufacturer_id")}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_id", value);
          formik.setFieldValue("vehicle_model_id", "");
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        labelClassName="vehicle-manufacturer"
      >
        {isVmansSuccess &&
          vMans.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Vehicle Model"
        {...formik.getFieldProps("vehicle_manufacturer_model_id")}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_model_id", value);
          formik.setFieldValue("vehicle_model_id", "");
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        labelClassName="vehicle-model"
      >
        {isVehicleManModelSuccess &&
          vehicleManModels.data.map((vehicleModel) => (
            <li key={vehicleModel.id} value={vehicleModel.id}>
              {vehicleModel.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Vehicle Specification"
        {...formik.getFieldProps("vehicle_model_id")}
        error={formik.touched.vehicle_model_id && formik.errors.vehicle_model_id}
        onChange={(value) => formik.setFieldValue("vehicle_model_id", value)}
        labelClassName="vehicle-specification"
      >
        {isVehicleSpecificationSuccess &&
          vehicleSpecifications.data.map((vManVar) => (
            <li key={vManVar.id} value={vManVar.id}>
              Name: {vManVar.name}, Variant: {vManVar.vehicle_manufacturer_model_variant_name},
              Engine Capacity: {vManVar.engine_capacity}, Capacity: {vManVar.capacity}, Year:{" "}
              {vManVar.year}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QIMultiObjectSelect
        label="Commands"
        className={"batch-comands-key-value"}
        {...formik.getFieldProps("commands")}
        error={formik.touched.commands && formik.errors.commands}
        onChange={(value) => formik.setFieldValue("commands", value)}
        headers={commandsHeader}
        validator={batchCommandValidator}
      />
    </FormContainer>
  );
};
