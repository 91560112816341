import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sprite from "../../assets/icons.svg";

export const QICalendar = ({
  value,
  onChange,
  className = "",
  labelClass = "",
  label,
  inputWrapperClass = "",
  inputClass,
  dateFormat = "dd/MM/yyyy",
  maxDate,
  minDate,
  id,
  showTimeSelect,
  timeIntervals,
  maxTime,
  minTime,
  readOnly,
  error,
  disabled,
  isSubscriptionExpired,
  ...props
}) => {
  // Format the date with extra spacing between date and time
  const formatDate = (date) => {
    if (!date) return "";

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);

    if (!showTimeSelect) return formattedDate;

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);

    return `${formattedDate} | ${formattedTime}`;
  };

  const CustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <>
      <button className={className} onClick={onClick} ref={ref} disabled={isSubscriptionExpired}>
        {value || "Select"}
      </button>
    </>
  ));

  return (
    <div
      className={`qi-input qi-form-element ${error && !disabled ? "error" : ""} ${
        disabled && "disabled"
      } ${className}`}
    >
      {label && (
        <label className={`qi-input_label ${labelClass}`} data-testid="input-label">
          {label}
        </label>
      )}

      <div className={`qi-input_wrapper date-range-picker ${inputWrapperClass}`}>
        <DatePicker
          className={`${readOnly ? "form-control-plaintext" : "date-range "} ${inputClass}`}
          showMonthDropdown
          showYearDropdown
          dateFormat={showTimeSelect ? "dd/MM/yyyy | h:mm aa" : dateFormat}
          maxDate={maxDate}
          minDate={minDate}
          id={id}
          showTimeSelect={false}
          timeIntervals={timeIntervals}
          maxTime={maxTime}
          minTime={minTime}
          selected={value}
          onChange={onChange}
          customInput={<CustomInput className="example-custom-input" />}
          timeCaption="Time"
          timeInputLabel="Time:"
          showTimeInput={showTimeSelect}
          popperClassName="time-picker-popper"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
          disabled={isSubscriptionExpired}
          {...props}
        ></DatePicker>
        {isSubscriptionExpired && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p className="qi-input_error_message">
              Your subscription has expired. Please renew to continue using this feature.
            </p>
          </div>
        )}
        {!readOnly && error && !disabled && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p
              className="qi-input_error_message "
              dangerouslySetInnerHTML={{ __html: `${error}` }}
            ></p>
          </div>
        )}
      </div>
    </div>
  );
};
