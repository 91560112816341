import React, { useState, useRef, useCallback } from "react";
import { Icon, Style, Fill, Stroke, Text } from "ol/style.js";
import { useGetDevicesTrackingQuery, useGetVariablesDataQuery } from "../services";
import { useGetVariablesQuery } from "../../Admin/services";
import { VehicleDetailsWindowView } from "../Vehicles/VehicleDetailsWindowView";
import { VehicleMap } from "../Vehicles/VehicleMap";
import { VehicleTrack } from "../Vehicles/VehicleTrack";
import { DeviceList } from "./DeviceList";
import { Dnd, MinimisedTab, useDndContainer, WindowTab } from "../Dnd";
import { locationImg, locArrowImg } from "../assets/mapIcons";
import { useEffect } from "react";
import { useWebSocket } from "../../../hooks";
import { QICustomDateRangePicker, QIDragAndDropDetails, QISwitch } from "../../../components";
import moment from "moment";
import { TripsContainer } from "../Vehicles/Trips";
import { EventsContainer } from "../Vehicles/Events";
import sprite from "../../../assets/icons.svg";
//Data config json
import { HeaderDetails, ListDetails } from "../Shared/VehicleDetailsItems";
//Toast Message
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//TrackPoints
import TrackPoints from "../Vehicles/TrackPoints";
//Raw Data
import { RawData } from "../Vehicles/RawData";
//Trips
import Activities from "../Vehicles/Activities";
import { useDispatch } from "react-redux";
import { setVariableDatas } from "../../../reduxStore/features";
import { getLocalizedString } from "../../../shared/translation";
import mapboxgl from "mapbox-gl";
import { debounce } from "../utils";
import EventsMap from "../Events/EventMap";
import TripsMap from "../Trips/TripsMap";
import { HorizontalScroll } from "../Shared/HorizontalScroll";
import { useGetPluginsQuery } from "../../ManagementPortal/services";
import { PluginContainer } from "../../Plugins";
import { usePagination } from "../../../hooks";

export const DeviceContainer = () => {
  const dispatch = useDispatch();

  const [clientId, setClientId] = useState(null);
  const [mapProvider, setMapProvider] = useState();
  const [mapboxAccessToken, setMapBoxAccessToken] = useState();
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState();
  const [mapRef, setMapRef] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [sourceIds, setSourceIds] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(0);
  const [mapData, setMapData] = useState({});
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [selectedVehiclePlate, setselectedVehiclePlate] = useState(null);
  const [selectedDeviceName, setselectedDeviceName] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [dockAlign, setDockAlign] = useState(null);
  const [mapExpand, setMapExpand] = useState(false);
  const [showTrack, setShowTrack] = useState(false);
  const [trackMapRef, setTrackMapRef] = useState(null);
  const [simpleSearch, setSimpleSearch] = useState("");
  const [currentTab, setCurrentTab] = useState("Map");
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [resetCount, setResetCount] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(null);
  // Config Set
  const [showConfig, setShowConfig] = useState(false);
  const [checkData, setCheckData] = useState([]);
  const [clientConfig, setClientConfig] = useState({});
  const [maximizeMinimize, setmaximizeMinimize] = useState(false);
  const [toggleTrackPointTab, setToggleTrackPointTab] = useState(false);
  const [decode, setDecode] = useState(false);
  const [order, setOrder] = useState(false);
  const [variableData, setVariableData] = useState({});
  const [variablePage, setVariablePage] = useState(0);
  const [vehicleData, setVehicleData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true); // Flag to check if initial load
  const [mapPoint, setMapPoint] = useState(null);
  const [devicesData, setDevicesData] = useState([]); // Stores vehicles plate no and associated device imei
  const [sortedDataDisplay, setSortedDataDisplay] = useState(true);
  const [nonAdminUserId, setNonAdminUserId] = useState("");

  //Ref
  const configDivRef = useRef(null);
  const configButtonRef = useRef(null);

  //Calculate Latest Date
  useEffect(() => {
    try {
      setSelectedRange({
        startDate: moment().subtract(24, "hours"),
        endDate: moment(),
      });
    } catch (e) {}
  }, [deviceId]);

  const openDeviceModal = () => {
    setShowDetails(false);
    addNewBox(deviceId);
  };
  const { realTimedata } = useWebSocket(String(clientId), "all", "sub_all");

  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => {
        return item.id === selectedClientId;
      }
    );
    setClientId(currentUser?.client?.id);
    try {
      if (currentUser?.client?.config_json) {
        const mapProvider = JSON.parse(currentUser?.client?.config_json)?.tracking?.web?.map;
        const clientConfig = JSON.parse(currentUser?.client?.config_json);

        if (mapProvider?.provider === "mapbox" && mapProvider?.mapboxAccessToken) {
          setClientConfig(clientConfig);
          setMapProvider("mapbox");
          setMapBoxAccessToken(mapProvider.mapboxAccessToken);
          setGoogleMapsApiKey(null);
        } else if (mapProvider?.provider === "google" && mapProvider?.googleMapsApiKey) {
          setClientConfig(clientConfig);
          setMapProvider("google");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(mapProvider.googleMapsApiKey);
        } else {
          setClientConfig(clientConfig);
          setMapProvider("qiMap");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(null);
        }
      }
    } catch (e) {
      console.log(e);
    }

    //clean up client id on component unmount
    return () => setClientId(null);
  }, []);

  const [q, setQ] = useState("");
  const [page1, setPage1] = useState(1);
  const [perPage1, setPerPage1] = useState(20);
  const pagination = {
    page: page1,
    perPage: perPage1,
    onPageChange: setPage1,
    onPerPageChange: setPerPage1,
  };

  // When page changes, any selected row should be removed
  useEffect(() => {
    if (currentTab === "RawData") {
      setCurrentTab("Map");
    }
    setSelectedRowId(null);
    removeLayerOnClose();
    setDeviceId(null);
    setDockAlign("cross");
  }, [page1]);

  // When page changes from active to all and vice versa, any selected row should be removed
  useEffect(() => {
    if (currentTab === "RawData") {
      setCurrentTab("Map");
    }
    setSelectedRowId(null);
    removeLayerOnClose();
    setDeviceId(null);
    setDockAlign("cross");
  }, [sortedDataDisplay]);

  useEffect(() => {
    if (sortedDataDisplay) {
      setQ(simpleSearch);
    } else {
      let obj = {
        name_or_device_id_or_filter_device_ph_with_country_code_cont: simpleSearch.toUpperCase(),
        filter_device_imei_cont: simpleSearch.toUpperCase(),
        m: "or",
      };
      setQ(encodeURIComponent(JSON.stringify(obj)));
    }
    setPage1(1);
  }, [simpleSearch]);

  useEffect(() => {
    if (sortedDataDisplay) {
      setQ(simpleSearch);
    } else {
      let obj = {
        name_or_device_id_or_filter_device_ph_with_country_code_cont: simpleSearch.toUpperCase(),
        filter_device_imei_cont: simpleSearch.toUpperCase(),
        m: "or",
      };
      setQ(encodeURIComponent(JSON.stringify(obj)));
    }
    setPage1(1);
  }, [simpleSearch]);

  useEffect(() => {
    setSimpleSearch("");
    setPage1(1);
    setPerPage1(20);
    if (sortedDataDisplay) {
      setQ("");
    } else {
      let obj = {
        name_or_plate_number_cont: simpleSearch.toUpperCase(),
        filter_device_imei_cont: simpleSearch.toUpperCase(),
        m: "or",
      };
      setQ(encodeURIComponent(JSON.stringify(obj)));
    }
  }, [sortedDataDisplay]);

  const getQueryParams = () => {
    if (sortedDataDisplay && nonAdminUserId !== "") {
      return {
        page: pagination?.page - 1,
        per_page: pagination?.perPage,
        filter: true,
        device_ids: sourceIds,
        q: q,
      };
    } else if (sortedDataDisplay && nonAdminUserId === "") {
      return {
        page: pagination?.page - 1,
        per_page: pagination?.perPage,
        filter: true,
        q: q,
      };
    } else if (!sortedDataDisplay && nonAdminUserId !== "") {
      return {
        page: pagination?.page - 1,
        per_page: pagination?.perPage,
        device_ids: sourceIds,
      };
    } else if (!sortedDataDisplay && nonAdminUserId === "") {
      return {
        page: pagination?.page - 1,
        per_page: pagination?.perPage,
      };
    }
  };

  /* Get vehicle position and plot in map */
  const { data: trackingData = { data: [] } } = useGetDevicesTrackingQuery(
    getQueryParams()
    // fields: "device_data.source_id,device_data.gps,device_data.velocity,device_data.ignition",
  );

  /* Get Variable Data Mappings */

  const { data: mappingData } = useGetVariablesDataQuery({ per_page: 2000, page: variablePage });

  /* Load data to get data for show details */
  useEffect(() => {
    let device;
    if (trackingData && trackingData?.latest_data?.length) {
      let deviceIds = [];
      let deviceSourceId;
      let plateNo;
      const tempDeviceData = trackingData?.latest_data.map((datum) => {
        if (
          datum?.device_data?.source_id ||
          datum?.device_reply?.source_id ||
          datum?.login?.source_id ||
          datum?.registration?.source_id ||
          datum?.heartbeat?.source_id
        ) {
          deviceSourceId =
            datum?.device_data?.source_id ||
            datum?.device_reply?.source_id ||
            datum?.login?.source_id ||
            datum?.registration?.source_id ||
            datum?.heartbeat?.source_id;
          deviceIds.push(
            datum?.device_data?.source_id ||
              datum?.device_reply?.source_id ||
              datum?.login?.source_id ||
              datum?.registration?.source_id ||
              datum?.heartbeat?.source_id
          );
        }
        if (
          datum?.device_data?.plate_number?.v ||
          datum?.device_reply?.plate_number?.v ||
          datum?.login?.plate_number?.v ||
          datum?.registration?.plate_number?.v ||
          datum?.heartbeat?.plate_number?.v
        ) {
          plateNo =
            datum?.device_data?.plate_number?.v ||
            datum?.device_reply?.plate_number?.v ||
            datum?.login?.plate_number?.v ||
            datum?.registration?.plate_number?.v ||
            datum?.heartbeat?.plate_number?.v;
        }

        return { device_name_list: deviceSourceId, plate_number: plateNo, id: deviceSourceId };
      });

      setDevicesData(tempDeviceData);

      device =
        (trackingData?.latest_data || [])?.find(
          ({ device_data }) => device_data?.source_id === deviceId
        ) || {};
      setDeviceData(device?.device_data || {});
    }
    if (trackingData && !trackingData?.latest_data?.length) {
      setDeviceData({});
      setDevicesData([]);
    }
  }, [trackingData, deviceId]);

  // Returns the latest time
  const getLatestTime = (t1, t2, t3, t4, t5, t6, t7, t8, t9, t10, t11, t12) => {
    let validTime1 = 0,
      validTime2 = 0,
      validTime3 = 0,
      validTime4 = 0,
      validTime5 = 0,
      validTime6 = 0,
      validTime7 = 0,
      validTime8 = 0,
      validTime9 = 0,
      validTime10 = 0,
      validTime11 = 0,
      validTime12 = 0;
    if (typeof t1 == "number") {
      validTime1 = t1;
    }
    if (typeof t2 == "number") {
      validTime2 = t2;
    }
    if (typeof t3 == "number") {
      validTime3 = t3;
    }
    if (typeof t4 == "number") {
      validTime4 = t4;
    }
    if (typeof t5 == "number") {
      validTime5 = t5;
    }
    if (typeof t6 == "number") {
      validTime6 = t6;
    }
    if (typeof t7 == "number") {
      validTime7 = t7;
    }
    if (typeof t8 == "number") {
      validTime8 = t8;
    }
    if (typeof t9 == "number") {
      validTime9 = t9;
    }
    if (typeof t10 == "number") {
      validTime10 = t10;
    }
    if (typeof t11 == "number") {
      validTime11 = t11;
    }
    if (typeof t12 == "number") {
      validTime12 = t12;
    }

    return Math.max(
      validTime1,
      validTime2,
      validTime3,
      validTime4,
      validTime5,
      validTime6,
      validTime7,
      validTime8,
      validTime9,
      validTime10,
      validTime11,
      validTime12
    );
  };

  //Debounce to optimize performance

  const processTrackingData = useCallback(
    debounce((trackingData, realTimedata) => {
      const filteredPosition = {};
      const trackingPoints = [];

      trackingData.latest_data.forEach((data) => {
        const sourceId =
          data?.device_data?.source_id ||
          data?.heartbeat?.source_id ||
          data?.login?.source_id ||
          data?.registration?.source_id;
        const realTimeDataForSource = realTimedata[sourceId];
        const position =
          realTimeDataForSource?.gps?.position || data?.device_data?.gps?.v?.position;
        const direction =
          realTimeDataForSource?.velocity?.direction || data?.device_data?.velocity?.v?.direction;
        let speed = realTimeDataForSource?.velocity?.speed || data?.device_data?.velocity?.v?.speed;

        const sourceTime = getLatestTime(
          mapData[sourceId]?.sourceTime,
          realTimeDataForSource?.source_time,
          data?.device_data?.source_time?.v,
          data?.device_data?.source_time,
          data?.heartbeat?.source_time?.v,
          data?.heartbeat?.source_time,
          data?.login?.source_time?.v,
          data?.login?.source_time,
          data?.device_reply?.source_time?.v,
          data?.device_reply?.source_time,
          data?.registration?.source_time?.v,
          data?.registration?.source_time
        );

        const serverTime =
          realTimeDataForSource?.server_time ||
          data?.device_data?.server_time?.v ||
          data?.device_data?.server_time ||
          data?.heartbeat?.server_time?.v ||
          data?.heartbeat?.server_time ||
          data?.login?.server_time?.v ||
          data?.login?.server_time ||
          data?.device_reply?.server_time?.v ||
          data?.device_reply?.server_time ||
          data?.registration?.server_time?.v ||
          data?.registration?.server_time;
        const plate_number = data?.device_data?.plate_number?.v || data?.heartbeat?.plate_number?.v;

        speed = speed == 0 || speed ? `${(speed * 3.6).toFixed(1)} km/h` : "Not Available";
        const ignition =
          realTimeDataForSource?.ignition ?? data?.device_data?.ignition?.v ?? "Unknown";

        if (sourceId) {
          filteredPosition[sourceId] = {
            speed,
            direction,
            ignition,
            sourceTime,
            plate_number,
            serverTime,
          };
          trackingPoints.push({ ...position, imei: sourceId, direction, speed, plate_number });
        }
      });

      setMapData(filteredPosition);
      setTrackingPoints(trackingPoints.filter((device) => device.lat !== 0 && device.lng !== 0));
    }, 3000),
    []
  );

  useEffect(() => {
    if (!trackingData?.latest_data) return;
    processTrackingData(trackingData, realTimedata);
  }, [trackingData, realTimedata, simpleSearch, processTrackingData]);

  //Debounce to optimize performance End

  /* Variable Data Mapping */
  useEffect(() => {
    if (mappingData?.data?.length) {
      mappingData?.data?.map((item, id) => {
        const format = item?.section_value;
        setVariableData((prev) => ({
          ...prev,
          [`${format ? format + "." : ""}${item?.name}`]: { ...item },
        }));
        return null;
      });
      setVariablePage((prev) => prev + 1);
    }
  }, [mappingData]);

  /* Dispatch Variable Data to Global Store */
  useEffect(() => {
    if (variableData) {
      dispatch(setVariableDatas(variableData));
    }
  }, [variableData, dispatch]);

  //Map tracking
  useEffect(() => {
    const layerName = `test_${count}`;
    const prevLayer = `test_${count - 1}`;
    const updatedTrackingPoints = [];
    trackingPoints?.map((point) => {
      if (
        point?.direction != undefined &&
        point?.lat != undefined &&
        point?.lng != undefined &&
        point?.plate_number != undefined
      ) {
        updatedTrackingPoints?.push(point);
      }
    });
    if (mapProvider === "qiMap") {
      if (mapRef && trackingPoints.length) {
        count && mapRef.removeLayer(prevLayer);
        mapRef.addLayer({ name: layerName });
        mapRef.drawTrack({
          layerName: layerName,
          fitWithinView: false,
          trackType: "point",
          data: [{ coordinates: updatedTrackingPoints }],
          style: {
            point: {
              style: (feature) => {
                const isSelected = feature.plate_number === selectedVehiclePlate;
                return new Style({
                  zIndex: isSelected ? 10 : 1, // Ensure selected vehicle is on top
                  image: new Icon({
                    color: "#fff",
                    crossOrigin: "anonymous",
                    src: locationImg,
                    imgSize: [26, 26],
                    rotation: feature.direction,
                  }),
                  text: new Text({
                    text: feature.plate_number,
                    font: "14px Calibri,sans-serif",
                    fill: new Fill({
                      color: "#000", // Black font
                    }),
                    backgroundFill: new Fill({
                      color: "#fff",
                    }),
                    padding: [5, 10, 5, 10], // Padding inside the box
                    offsetY: -30, // Moves the text box above the marker
                    textAlign: "center", // Aligns the text in the center of the box
                    backgroundStroke: new Stroke({
                      color: "#999", // Border color around the box
                      width: 1, // Border width
                    }),
                  }),
                });
              },
            },
          },
        });
        // Only fit the map on initial load
        if (initialLoad) {
          try {
            mapRef.performFit(layerName);
            setInitialLoad(false); // Disable initial load after first fit
          } catch (e) {}
        }
        //mapRef.performFit(layerName);
      } else {
        mapRef?.removeLayer(`test_${count - 1}`);
      }
      try {
        //Navigating back and forth from Live Tracking
        deviceId && showPontOnMap(deviceId, selectedVehiclePlate);
      } catch (e) {}
    } else if (mapRef && updatedTrackingPoints.length && mapProvider === "mapbox") {
      const onStyleLoaded = () => {
        // Register a custom icon
        mapRef.loadImage(
          "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
          (error, image) => {
            if (error) throw error;

            // Check if the image is already added
            if (!mapRef.hasImage("custom-icon")) {
              mapRef.addImage("custom-icon", image);
            }

            // Transform data for Mapbox
            const cleanedData = updatedTrackingPoints.map((item) => ({
              coordinates: [item.lng, item.lat],
              plate_number: item.plate_number,
              direction: item.direction,
            }));

            // Remove the previous layer if it exists
            if (mapRef.getLayer(layerName)) {
              mapRef.removeLayer(layerName);
              mapRef.removeSource(layerName);
            }

            // Add a new GeoJSON source with tracking points
            mapRef.addSource(layerName, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: cleanedData.map((point) => ({
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: point.coordinates, // [lng, lat]
                  },
                  properties: {
                    plate_number: point.plate_number,
                    direction: point.direction,
                  },
                })),
              },
            });

            // Add a layer for the points
            mapRef.addLayer({
              id: layerName,
              type: "symbol",
              source: layerName,
              layout: {
                "icon-image": "custom-icon", // Custom icon registered in the map
                "icon-size": 0.6,
                "icon-rotate": ["get", "direction"], // Rotate icon based on direction
                "text-field": ["get", "plate_number"],
                "text-size": 12,
                "text-offset": [0, -1.5], // Move text above the marker
                "text-anchor": "top",
                "text-padding": 10, // Add padding around text (equivalent to padding: 5px 10px)
                "text-allow-overlap": true, // Allow text to overlap with other elements
                "text-justify": "center",
              },
              paint: {
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 1,
                // Add background styling with border
                "text-opacity": 1,
                "text-halo-blur": 0,
                // Add a white background with border
                "text-color": "#000000",
                "text-halo-color": "#ffffff", // White background
                "text-halo-width": 2, // Background size
                // Unfortunately, Mapbox GL JS doesn't directly support border-radius for text
                // We can add a border by using a different color
                "text-halo-blur": 0.5, // This creates a slight blur effect that can simulate rounded corners
              },
            });

            // Ensure selected vehicle appears   on top
            mapRef.setLayoutProperty(layerName, "icon-allow-overlap", true);
            mapRef.setPaintProperty(layerName, "icon-opacity", [
              "case",
              ["==", ["get", "plate_number"], selectedVehiclePlate],
              1, // Selected vehicle opacity
              0.6, // Non-selected opacity
            ]);

            // Fit the map to the tracking points on initial load
            if (initialLoad) {
              const coordinates = cleanedData.map((p) => p.coordinates);
              const bounds = coordinates.reduce(
                (bounds, coord) => bounds.extend(coord),
                new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
              );

              mapRef.fitBounds(bounds, { padding: 50, duration: 1000 });
              setInitialLoad(false);
            }

            // Center on a selected vehicle dynamically
            if (deviceId && selectedVehiclePlate) {
              const selectedPoint = cleanedData.find(
                (p) => p.plate_number === selectedVehiclePlate
              );
              if (selectedPoint) {
                mapRef.flyTo({
                  center: selectedPoint.coordinates,
                  zoom: 15,
                  speed: 0.8,
                });
              }
            }
          }
        );
      };

      // Check if the style is already loaded
      if (mapRef.isStyleLoaded()) {
        onStyleLoaded();
      } else {
        // Wait for the style to load
        mapRef.on("style.load", onStyleLoaded);
      }
    } else if (mapProvider === "google" && mapRef && updatedTrackingPoints.length) {
      // For Google Maps
      const bounds = new google.maps.LatLngBounds();

      // Clear previous markers and overlays
      if (window.googleMarkers) {
        window.googleMarkers.forEach((marker) => marker.setMap(null)); // Clear markers
      }
      if (window.googleOverlays) {
        window.googleOverlays.forEach((overlay) => overlay.setMap(null)); // Clear overlays
      }

      // Initialize arrays
      window.googleMarkers = [];
      window.googleOverlays = [];

      // Add markers with styled labels for tracking points
      updatedTrackingPoints.forEach((point) => {
        const position = { lat: point.lat, lng: point.lng };

        // Create the marker
        const marker = new google.maps.Marker({
          position,
          map: mapRef,
          icon: {
            url: locationImg, // Marker icon
            scaledSize: new google.maps.Size(26, 26),
          },
          zIndex: point.plate_number === selectedVehiclePlate ? 10 : 1, // Selected vehicle on top
        });

        // Custom label styling
        const labelDiv = document.createElement("div");
        labelDiv.style.position = "absolute";
        labelDiv.style.background = "#fff";
        labelDiv.style.color = "#000";
        labelDiv.style.padding = "5px 10px"; // Increased horizontal padding
        labelDiv.style.border = "1px solid #999"; // Changed border color
        labelDiv.style.borderRadius = "4px";
        labelDiv.style.textAlign = "center";
        labelDiv.style.font = "14px Calibri, sans-serif";
        labelDiv.style.transform = "translate(-50%, -50%)"; // Center the label
        labelDiv.innerText = point.plate_number;

        // Add a custom overlay
        const overlay = new google.maps.OverlayView();
        overlay.onAdd = function () {
          const pane = overlay.getPanes().overlayLayer;
          pane.appendChild(labelDiv);
        };
        overlay.draw = function () {
          const projection = overlay.getProjection();
          const pointPos = projection.fromLatLngToDivPixel(position);
          labelDiv.style.left = `${pointPos.x}px`;
          labelDiv.style.top = `${pointPos.y - 30}px`; // Offset above marker
        };
        overlay.onRemove = function () {
          if (labelDiv.parentNode) {
            labelDiv.parentNode.removeChild(labelDiv);
          }
        };
        overlay.setMap(mapRef);

        // Extend bounds for auto-fit
        bounds.extend(position);

        // Save marker and overlay for later removal
        window.googleMarkers.push(marker);
        window.googleOverlays.push(overlay);
      });

      // Fit bounds on initial load
      if (initialLoad) {
        mapRef.fitBounds(bounds);
        setInitialLoad(false);
      }

      if (deviceId && selectedVehiclePlate) {
        const selectedPoint = updatedTrackingPoints.find(
          (p) => p.plate_number === selectedVehiclePlate
        );
        if (selectedPoint) {
          // Center the map on the selected vehicle
          mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });

          // Set zoom level
          mapRef.setZoom(15);

          // Optionally, you can animate this using setTimeout for gradual zoom
          mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });
          setTimeout(() => {
            mapRef.setZoom(15);
          }, 500); // Adjust delay for smoother animation
        }
      }
    }
    //Increase layer count
    setCount((c) => c + 1);
  }, [mapRef, trackingPoints, deviceId, initialLoad, mapProvider]);

  // Show single point on map (on selection)
  function showPontOnMap(data_id, plate_number) {
    let imei_info = trackingPoints.find((device_data) => device_data.imei === data_id);
    let layerName = `imei-details_${selectedRow}`;
    // selectedRow && mapRef.removeLayer(`imei-details_${selectedRow - 1}`);
    selectedRow && mapRef.removeLayerSource(`imei-details_${selectedRow - 1}`);
    mapRef.addLayer({ name: layerName });
    mapRef.drawTrack({
      layerName: layerName,
      fitWithinView: false,
      trackType: "point",
      data: [{ coordinates: [imei_info] }],
      style: {
        point: {
          style: (feature) => {
            const isSelected = feature.plate_number === selectedVehiclePlate;

            return new Style({
              zIndex: isSelected ? 10 : 1, // Ensure selected vehicle is on top
              image: new Icon({
                color: "#fff",
                crossOrigin: "anonymous",
                src: locArrowImg,
                imgSize: [36, 36],
                rotation: feature.direction,
              }),
            });
          },
        },
      },
      tooltip: {
        showOn: "hover",
        content: (feature) => {
          let data = feature.get("data");
          return `<div><strong>${
            plate_number || data?.plate_number || data?.imei || ""
          }</strong></div>
          <div>${data?.address?.name || data?.address?.city || data?.address?.country || ""}</div>
          <div>${data?.speed || ""}</div>`;
        },
      },
    });
    // Only fit the map on initial load
    if (initialLoad) {
      mapRef.performFit(layerName);
      setInitialLoad(false); // Disable initial load after first fit
    }
    //mapRef.performFit(layerName);
    setSelectedRow((i) => i + 1);
  }

  const removeLayerOnClose = () => {
    try {
      if (mapProvider === "mapbox") {
        // Helper function to remove a Mapbox layer and its source
        const removeLayerAndSource = (id) => {
          if (trackMapRef.getLayer(id)) {
            trackMapRef.removeLayer(id);
          }
          if (trackMapRef.getSource(id)) {
            trackMapRef.removeSource(id);
          }
        };

        // Remove layers and sources specific to Mapbox
        if (mapRef) {
          removeLayerAndSource(`imei-details_${selectedRow - 1}`);
        }
        if (trackMapRef) {
          removeLayerAndSource(`track_${deviceId}`);
        }
      } else if (mapProvider === "google") {
        // Clear all overlays (markers, polylines) specific to Google Maps
        const removeGoogleOverlays = (prefix) => {
          for (let i = 0; i < count; i++) {
            const overlayGroup = window[`${prefix}_${i}_google`];
            if (overlayGroup) {
              overlayGroup.forEach((overlay) => overlay.setMap(null));
              window[`${prefix}_${i}_google`] = null; // Clear reference
            }
          }
        };

        // Remove Google-specific overlays
        if (mapRef) {
          removeGoogleOverlays(`imei-details`);
        }
        if (trackMapRef) {
          removeGoogleOverlays(`track`);
        }
      } else if (mapProvider === "qiMap") {
        // Specific handling for `qiMap`
        mapRef && mapRef.removeLayerSource(`imei-details_${selectedRow - 1}`);
        trackMapRef && trackMapRef.removeLayerSource(`track_${deviceId}`);
      }
    } catch (error) {
      console.error("Error removing layers or overlays:", error);
    }
  };

  const removeRowSelection = () => {
    setHighlight(null);
    setSelectedRowId(null);
  };

  const { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange } =
    useDndContainer();

  //Show Map
  const showMap = () => {
    setCurrentTab("Map");
    setShowTrack(false);
  };
  //Show Live Track
  const showLiveTrack = () => {
    setCurrentTab("LiveTrack");
    setShowTrack(true);
  };

  //Show Activities
  const showActivities = () => {
    setCurrentTab("Activities");
    setShowTrack(true);
  };

  //Show Trips
  const showTrips = () => {
    setCurrentTab("Trips");
    setShowTrack(true);
  };

  //Show Events
  const showEvents = () => {
    setCurrentTab("Events");
    setShowTrack(true);
  };

  //Show Raw Data
  const showRawData = () => {
    setCurrentTab("RawData");
    setShowTrack(true);
  };

  //Show Trackpoints
  const showTrackpoints = () => {
    setCurrentTab("TrackPoints");
    setShowTrack(true);
  };

  //clear live track on type
  useEffect(() => {
    if (simpleSearch) {
      setShowTrack(false);
      setDeviceId(null);
      setDockAlign("cross");
      setHighlight(null);
    }
  }, [simpleSearch]);

  //Hide Vehicle Details for Track Points
  useEffect(() => {
    if (currentTab !== "Reports") {
      selectedDeviceName && setShowDetails(true);
    }
  }, [selectedDeviceName, currentTab]);

  //Column config close on clicking outside the column config div
  const handleClickOutside = (event) => {
    if (
      configButtonRef.current &&
      configDivRef.current &&
      !configDivRef.current.contains(event.target) &&
      !configButtonRef.current.contains(event.target)
    ) {
      // Clicked outside the config div, set showConfig to false
      setShowConfig(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  const [activitiesTimeRange, setActivitiesTimeRange] = useState(selectedRange);
  const [refreshMap, setRefreshMap] = useState(false);

  useEffect(() => {
    setRefreshMap((prev) => !prev);
  }, [selectedRange, currentTab]);

  //To set the total count of data in the list of activity and trips
  const [listCount, setListCount] = useState(0);

  // Add these new hooks
  const { page, perPage } = usePagination();

  const { data = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  const addPlugins = data.data.filter(
    (plugin) =>
      plugin.tracker_containers.map((s) => parseInt(s, 10)).includes(2) && plugin.is_enabled
  );
  const isPluginTab = addPlugins.some((plugin) => plugin.name === currentTab);
  const selectedPlugin = data.data.find((plugin) => plugin.name === currentTab);

  // Add this new function
  const showdetailsTabs = (tab) => {
    setShowDetails(false);
    setDockAlign("cross");
    setCurrentTab(tab);
  };

  return (
    <>
      <main className="tracking-app-main-container item-list-right-layout">
        <article className={`tracking-app-main-container_block ${currentTab}`}>
          <DeviceList
            setDetails={setShowDetails}
            setDeviceId={setDeviceId}
            setSourceIds={setSourceIds}
            sourceIds={sourceIds}
            showPontOnMap={showPontOnMap}
            mapData={mapData}
            setselectedVehiclePlate={setselectedVehiclePlate}
            setselectedDeviceName={setselectedDeviceName}
            setHighlight={setHighlight}
            highlight={highlight}
            dockAlign={dockAlign}
            setDockAlign={setDockAlign}
            setShowDetails={setShowDetails}
            removeLayerOnClose={removeLayerOnClose}
            mapExpand={mapExpand}
            setMapExpand={setMapExpand}
            trackMapRef={trackMapRef}
            deviceId={deviceId}
            setShowTrack={setShowTrack}
            setSimpleSearch={setSimpleSearch}
            simpleSearch={simpleSearch}
            currentTab={currentTab}
            removeRowSelection={removeRowSelection}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            setCurrentTab={setCurrentTab}
            data={sortedDataDisplay && devicesData}
            totalCountVehicles={sortedDataDisplay && trackingData?.total_count}
            paginationVehicles={sortedDataDisplay && pagination}
            setPageVehicles={setPage1}
            sortedDataDisplay={sortedDataDisplay}
            setSortedDataDisplay={setSortedDataDisplay}
            q={q}
            setNonAdminUserId={setNonAdminUserId}
            nonAdminUserId={nonAdminUserId}
          />
          <div className="map-and-tabs-container">
            <header className="tracking-app-main-container_block_header">
              <HorizontalScroll className="map-view-tabs">
                <ul className="map-and-details_tabs">
                  <li>
                    <span
                      className={`map-and-details_tabs_item ${currentTab === "Map" && "active"}`}
                      onClick={showMap}
                    >
                      {getLocalizedString("map", "Map")}
                    </span>
                  </li>
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "LiveTrack" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showLiveTrack()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("live_and_history", "Live & History")}
                        <span
                          className={
                            realTimedata?.[deviceId]?.ignition === true &&
                            realTimedata?.[deviceId]?.velocity?.speed > 0
                              ? "live-icon"
                              : ""
                          }
                        ></span>
                      </span>
                    </li>
                  )}
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "TrackPoints" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showTrackpoints()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("track_points", "Track Points")}
                      </span>
                    </li>
                  )}
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "Activities" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showActivities()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("activities", "Activities")}
                      </span>
                    </li>
                  )}
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "Trips" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showTrips()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("trips", "Trips")}
                      </span>
                    </li>
                  )}
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "Events" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showEvents()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("events", "Events")}
                      </span>
                    </li>
                  )}
                  {selectedRowId !== null && (
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === "RawData" && "active"
                        }`}
                        onClick={() =>
                          deviceId
                            ? showRawData()
                            : toast.error(
                                getLocalizedString("no_device_selected", "No Device Selected")
                              )
                        }
                      >
                        {getLocalizedString("raw_data", "Raw Data")}
                      </span>
                    </li>
                  )}
                  {addPlugins &&
                    addPlugins.map((plugin, index) => (
                      <li key={index}>
                        <span
                          className={`map-and-details_tabs_item ${
                            currentTab === plugin.name && "active"
                          }`}
                          onClick={() => showdetailsTabs(plugin.name)}
                        >
                          {getLocalizedString("plugins", plugin.name)}
                        </span>
                      </li>
                    ))}
                </ul>
              </HorizontalScroll>

              {/* Date Range Picker and Column Config Setting */}
              {currentTab !== "Map" && (
                <QICustomDateRangePicker
                  resetCount={resetCount}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  setResetCount={setResetCount}
                  openCalendar="left"
                />
              )}
            </header>

            <article
              className={`map-and-details ${dockAlign} ${
                maximizeMinimize ? "minimized-details" : ""
              }`}
            >
              {currentTab === "Map" ? (
                <VehicleMap
                  mapExpand={mapExpand}
                  mapRef={mapRef}
                  dockAlign={dockAlign}
                  setMapRef={setMapRef}
                  deviceId={deviceId}
                  selectedRowId={selectedRowId}
                  maximizeMinimize={maximizeMinimize}
                  mapProvider={mapProvider}
                  mapboxAccessToken={mapboxAccessToken}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : currentTab === "LiveTrack" ? (
                <VehicleTrack
                  dockAlign={dockAlign}
                  deviceId={deviceId}
                  realTimeData={realTimedata[deviceId]}
                  setTrackMapRef={setTrackMapRef}
                  trackMapRef={trackMapRef}
                  mapExpand={mapExpand}
                  selectedRange={selectedRange}
                  setResetCount={setResetCount}
                  maximizeMinimize={maximizeMinimize}
                  mapProvider={mapProvider}
                  mapboxAccessToken={mapboxAccessToken}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : currentTab === "TrackPoints" ? (
                <EventsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  mapPoint={mapPoint}
                  selectedRowId={deviceId}
                  setDockAlign={setDockAlign}
                  maximizeMinimize={maximizeMinimize}
                  refreshMap={refreshMap}
                />
              ) : currentTab === "Activities" ? (
                <TripsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  selectedRange={activitiesTimeRange}
                  maximizeMinimize={maximizeMinimize}
                  refreshMap={refreshMap}
                  mapProvider={mapProvider}
                  listCount={listCount}
                  mapboxAccessToken={mapboxAccessToken}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : currentTab === "Trips" ? (
                <TripsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  selectedRange={activitiesTimeRange}
                  maximizeMinimize={maximizeMinimize}
                  refreshMap={refreshMap}
                  mapProvider={mapProvider}
                  listCount={listCount}
                  mapboxAccessToken={mapboxAccessToken}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : currentTab === "Events" ? (
                <EventsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  mapPoint={mapPoint}
                  selectedRowId={deviceId}
                  setDockAlign={setDockAlign}
                  maximizeMinimize={maximizeMinimize}
                  refreshMap={refreshMap}
                />
              ) : isPluginTab ? (
                <PluginContainer selectedPlugin={selectedPlugin} />
              ) : null}
              {showDetails === true && currentTab !== "RawData" && (
                <article className={`details-block`}>
                  <QIDragAndDropDetails
                    setDetails={setShowDetails}
                    setPopup={openDeviceModal}
                    deviceData={deviceData || {}}
                    removeLayerOnClose={removeLayerOnClose}
                    selectedVehiclePlate={selectedDeviceName}
                    removeRowSelection={removeRowSelection}
                    setDockAlign={setDockAlign}
                    dockAlign={dockAlign}
                    deviceId={deviceId}
                    realTimedata={realTimedata}
                    setDeviceId={setDeviceId}
                    showTrack={showTrack}
                    setShowTrack={setShowTrack}
                    headerName={getLocalizedString("device_name", "Device Name")}
                    headerDetails={
                      <HeaderDetails
                        realTimedata={realTimedata}
                        deviceData={deviceData}
                        deviceId={deviceId}
                        type="Devices"
                      />
                    }
                    listDetails={
                      <ListDetails
                        clientConfig={clientConfig}
                        realTimedata={realTimedata}
                        deviceData={deviceData}
                        deviceId={deviceId}
                      />
                    }
                    currentTab={currentTab}
                    maximizeMinimize={maximizeMinimize}
                    setmaximizeMinimize={setmaximizeMinimize}
                    customContent={
                      currentTab === "Activities" ||
                      currentTab === "Trips" ||
                      currentTab === "Events" ||
                      currentTab === "TrackPoints"
                        ? true
                        : false
                    }
                  >
                    {currentTab === "Activities" && (
                      <Activities
                        deviceId={deviceId}
                        selectedRange={selectedRange}
                        clientId={clientId}
                        selectedVehiclePlate={selectedVehiclePlate}
                        type="Vehicles"
                        setActivitiesTimeRange={setActivitiesTimeRange}
                        setListCount={setListCount}
                      />
                    )}
                    {currentTab === "Trips" && (
                      <TripsContainer
                        resetCount={resetCount}
                        setResetCount={setResetCount}
                        deviceId={deviceId}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        clientId={clientId}
                        selectedVehiclePlate={selectedVehiclePlate}
                        type="Vehicles"
                        setActivitiesTimeRange={setActivitiesTimeRange}
                        setListCount={setListCount}
                      />
                    )}
                    {currentTab === "Events" && (
                      <EventsContainer
                        resetCount={resetCount}
                        setResetCount={setResetCount}
                        deviceId={deviceId}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        clientId={clientId}
                        selectedVehiclePlate={selectedVehiclePlate}
                        type="Vehicles"
                        setDeviceId={setDeviceId}
                        setDockAlign={setDockAlign}
                        setMapPoint={setMapPoint}
                      />
                    )}

                    {currentTab === "TrackPoints" && (
                      <>
                        <section className="tracking-app-main-container_block_header_secondary">
                          {/* Date Range Picker and Column Config Setting */}

                          <strong
                            className="config-button"
                            ref={configButtonRef}
                            onClick={() => {
                              checkData?.length > 0
                                ? setShowConfig((prev) => !prev)
                                : toast.error(
                                    getLocalizedString(
                                      "no_column_data_available",
                                      "No column data available"
                                    )
                                  );
                            }}
                            title="Column Configuration"
                          >
                            <svg className="icon">
                              <use href={`${sprite}#settings`}></use>
                            </svg>

                            <span> Column Configuration</span>
                          </strong>
                        </section>
                        <TrackPoints
                          deviceId={deviceId}
                          setDeviceId={setDeviceId}
                          liveData={realTimedata}
                          selectedVehiclePlate={selectedVehiclePlate}
                          selectedRange={selectedRange}
                          setResetCount={setResetCount}
                          showConfig={showConfig}
                          setCheckData={setCheckData}
                          configDivRef={configDivRef}
                          setMapPoint={setMapPoint}
                          setDockAlign={setDockAlign}
                        />
                      </>
                    )}
                  </QIDragAndDropDetails>
                </article>
              )}
            </article>
            {currentTab === "RawData" && (
              <>
                <section className="tracking-app-main-container_block_header_secondary">
                  {/* Date Range Picker and Column Config Setting */}
                  <div className="raw-data-section">
                    <>
                      <QISwitch
                        label="Decode"
                        value={decode}
                        onChange={(value) => {
                          setDecode((prev) => !prev);
                        }}
                        title={
                          decode
                            ? getLocalizedString(
                                "data_will_be_blank_if_format_is_binary",
                                "Data will be blank if format is binary"
                              )
                            : getLocalizedString(
                                "data_will_be_displayed_in_base64encoded_format",
                                "Data will be displayed in base64encoded format"
                              )
                        }
                        disabled={false}
                        readOnly={false}
                      />

                      <QISwitch
                        label={order ? "Asec" : "Desc"}
                        value={order}
                        onChange={(value) => {
                          setOrder((prev) => !prev);
                        }}
                        title={""}
                        disabled={false}
                        readOnly={false}
                      />
                    </>
                  </div>
                </section>
                <RawData
                  deviceId={deviceId}
                  selectedRange={selectedRange}
                  decode={decode}
                  order={order}
                />
              </>
            )}
          </div>
        </article>
      </main>

      {dockAlign === "pop-out" && (
        <Dnd
          layoutName="trackingLayout"
          isItemsDragging={isItemsDragging}
          handleDragChange={handleDragChange}
          setDetails={setShowDetails}
        >
          {Object.keys(activeBoxes).map((key) => (
            <WindowTab
              key={key}
              id={key}
              label={key}
              {...activeBoxes[key].props}
              onClose={removeBox}
              onMinimise={revertBox}
              setShowDetails={setShowDetails}
              setDockAlign={setDockAlign}
              setDeviceId={setDeviceId}
              setShowTrack={setShowTrack}
              selectedVehiclePlate={selectedDeviceName}
              headerName={getLocalizedString("device_name", "Device Name")}
              setHighlight={setHighlight}
              removeLayerOnClose={removeLayerOnClose}
              removeRowSelection={removeRowSelection}
            >
              {currentTab === "Activities" ? (
                <Activities
                  deviceId={deviceId}
                  selectedRange={selectedRange}
                  clientId={clientId}
                  selectedVehiclePlate={selectedVehiclePlate}
                  type="Vehicles"
                />
              ) : isPluginTab ? (
                <PluginContainer selectedPlugin={selectedPlugin} />
              ) : (
                <VehicleDetailsWindowView
                  realTimedata={realTimedata}
                  deviceId={deviceId}
                  deviceData={deviceData}
                  clientConfig={clientConfig}
                  summaryDetails={
                    <HeaderDetails
                      realTimedata={realTimedata}
                      deviceData={deviceData}
                      deviceId={deviceId}
                      type="Devices"
                    />
                  }
                  listDetails={
                    <ListDetails
                      clientConfig={clientConfig}
                      realTimedata={realTimedata}
                      deviceData={deviceData}
                      deviceId={deviceId}
                    />
                  }
                />
              )}
            </WindowTab>
          ))}
        </Dnd>
      )}
      <ToastContainer />
    </>
  );
};
